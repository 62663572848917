import { takeLatest } from "@redux-saga/core/effects";
import { put, call } from "typed-redux-saga";
import { actions } from "./dealingRatesSlice";
import { DealingRatesApi } from "./dealingRatesApi";

export function* pairListSaga() {
  yield takeLatest(actions.fetchPairListStart, function* (action) {
    try {
      const result: { pairs: Array<string> } = yield* call(
        DealingRatesApi.getPairs,
        action.payload
      );
      yield* put(actions.fetchPairListSuccess({ pairs: result.pairs }));
    } catch (e) {
      if (e instanceof Error) {
        yield* put(actions.fetchPairListFailure(e.message));
      }
    }
  });
}

export function* pairRateSaga() {
  yield takeLatest(actions.fetchPairRateStart, function* (action) {
    try {
      const result = yield* call(DealingRatesApi.getRate, action.payload);
      yield* put(actions.fetchPairRateSuccess(result));
    } catch (e) {
      if (e instanceof Error) {
        yield* put(actions.fetchPairRateFailure(e.message));
      }
    }
  });
}

export function* pairSetRateSaga() {
  yield takeLatest(actions.fetchSetPairRateStart, function* (action) {
    try {
      const result = yield* call(DealingRatesApi.setRate, action.payload);
      yield* put(actions.fetchSetPairRateSuccess(result));
    } catch (e) {
      if (e instanceof Error) {
        yield* put(actions.fetchSetPairRateFailure(e.message));
      }
    }
  });
}

export function* pairClearRateSaga() {
  yield takeLatest(actions.fetchClearPairRateStart, function* (action) {
    try {
      const result = yield* call(DealingRatesApi.clearPairs, action.payload);
      yield* put(actions.fetchClearPairRateSuccess(result));
    } catch (e) {
      if (e instanceof Error) {
        yield* put(actions.fetchClearPairRateFailure(e.message));
      }
    }
  });
}
