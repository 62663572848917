import { createSlice } from "@reduxjs/toolkit";
import { FetchStatus, TGetMenuPayload, TMenuItems } from "./types";

const menuSlice = createSlice({
  name: "menu",
  initialState: {
    menuItems: [],
    fetchStatus: null,
  } as {
    menuItems: TMenuItems | [];
    fetchStatus: FetchStatus | null;
  },
  reducers: {
    fetchMenuStart: (state, action: { payload: TGetMenuPayload }) => {
      state.fetchStatus = FetchStatus.PENDING;
    },
    fetchMenuSuccess: (state, action: { payload: { menus: any } }) => {
      state.menuItems = action.payload.menus;
      state.fetchStatus = FetchStatus.SUCCESS;
    },
    fetchMenuFailure: (state, action) => {
      state.fetchStatus = FetchStatus.FAILURE;
      console.error("Loading Failure", action.payload);
    },
    resetMenu: (state) => {
      state.fetchStatus = null;
      state.menuItems = [];
    }
  },
});

export const { actions } = menuSlice;
export const menuReducer = menuSlice.reducer;
