/* eslint-disable react-hooks/exhaustive-deps */
import styles from "./TreasuryPage.module.css";
import { Header } from "../../components/header/Header";
import { useEffect, useRef, useState } from "react";
import { Navigate } from "react-router-dom";
import { AppPages, LinkButtons } from "../../types";
import {
  checkIsAuth,
  getDateAndTimeOfUpdate,
  getDateOfUpdate,
  returnFormedCCQuotesArray,
  returnFormedFiatQuotesArray,
  returnSortedTotalBalancesArray,
  returnTotalBalance,
  returnTotalBalanceInUSD,
  sortLpRestByCurr,
  sortSymbolsArray,
} from "../../helpers";
import { TabsMenu } from "../../components/tabs-menu/TabsMenu";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  clearlpsState,
  fetchLpsListStart,
  fetchlpsBalancesStart,
} from "../../api/lps";
import { Spinner } from "../../components/spinner/Spinner";
import {
  clearBanksState,
  fetchBanksListStart,
  fetchBanksBalancesStart,
} from "../../api/banks";
import { TableTotal } from "../../components/table/table-total/TableTotal";
import { returnTotalLpBalance } from "../../api/lps/lpHelpers";
import {
  fiatCurrencyArrayToFilter,
  returnFilterCurrencyArray,
  totalTableCurrencySettingsArray,
  totalTableСolumns,
} from "../../settings";
import { Filter } from "../../components/filter/Filter";
import { fetchCryptoQuotesStart } from "../../api/cryptoQuotes";
import { fetchAllNbrbRatesStart } from "../../api/rates";

const LINKS_LIST = Object.values(LinkButtons);
type TreasuryPageProps = {};

export const TreasuryPage: React.FC<TreasuryPageProps> = () => {
  const [selectedFilter, setSelectedFilter] = useState("all");

  const radioHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedFilter(event.target.value);
  };
  const rates = useAppSelector((state) => state.rates.nbrbAllRatesList);
  const [selectedLink, setSelectedLink] = useState(LinkButtons.TOTAL);
  const [isAuth, setIsAuth] = useState(true);
  const lpList = useAppSelector((state) => state.lps.lpsList);
  const bankList = useAppSelector((state) => state.banks.bankList);
  const lpBalancesStatus = useAppSelector(
    (state) => state.lps.fetchBalanceStatus
  );
  const lpsBalancesArray = useAppSelector((state) => state.lps.lpsBalances);
  const lpsBalancesArrayWithoutFree2ex = lpsBalancesArray.filter(
    (lp) => lp.lpId !== "free2ex_hot_id" && lp.lpId !== "free2ex_warm_id"
  );
  const free2exBalances = lpsBalancesArray.filter(
    (lp) => lp.lpId === "free2ex_hot_id" || lp.lpId === "free2ex_warm_id"
  );
  const targetRef = useRef(null);
  
  let arr = [];
  for(let lp of free2exBalances) {
    for(let item of lp.lpRests){
      arr.push(item)
    }
  }

  const free2exBalancesArray = sortLpRestByCurr(arr) ?? [];

  let currencyLpsArray: string[] = [];
  lpsBalancesArray.forEach((lp) => {
    lp.lpRests.forEach((item) => {
      if (!currencyLpsArray.includes(item.symbol)) {
        currencyLpsArray.push(item.symbol);
      }
    });
  });

  let errorAssets:string[] = [];
  const totalLpsBalancesArray = returnTotalLpBalance(
    lpsBalancesArrayWithoutFree2ex,
    currencyLpsArray
  );
  const banksBalancesStatus = useAppSelector(
    (state) => state.banks.fetchBalanceStatus
  );
  const banksBalancesArray = useAppSelector(
    (state) => state.banks.totalValuesArray
  );

  let totalBanksBalancesArray: Array<{symbol: string, amount: number}> = [];
  
  banksBalancesArray.forEach((symbol) => {
    totalBanksBalancesArray.push({
      symbol: symbol.symbol,
      amount: symbol.corpAmount ? (Number(symbol.amount) + Number(symbol.corpAmount)) : Number(symbol.amount)
    })
  })

  const quotes = useAppSelector((state) => state.cryptoQuotes.cryptoQuotes);

  const totalForeignBanksBalancesArray: Array<{
    symbol: string;
    amount: string | number;
  }> = [];

  let token = localStorage.getItem("accessToken");
  token = token ? JSON.parse(token) : "";
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(clearlpsState());
    dispatch(clearBanksState());
    dispatch(fetchBanksListStart({ accessToken: token }));
    dispatch(fetchAllNbrbRatesStart({ accessToken: token }));
    dispatch(fetchLpsListStart({ accessToken: token }));
    checkIsAuth().then((response) => setIsAuth(response));
  }, []);

  useEffect(() => {
    if (lpList.length > 0) {
      for (let lp of lpList) {
        if (lp.subLpIds) {
          for (let subLpId of lp.subLpIds) {
            dispatch(
              fetchlpsBalancesStart({
                lpId: lp.lpId,
                subLpId: subLpId,
                accessToken: token,
              })
            );
          }
        } else {
          dispatch(
            fetchlpsBalancesStart({ lpId: lp.lpId, accessToken: token })
          );
        }
      }
    }
  }, [lpList.length]);

  useEffect(() => {
    if (bankList.length > 0) {
      for (let bank of bankList) {
        dispatch(
          fetchBanksBalancesStart({ bankId: bank.bankId, accessToken: token })
        );
      }
    }
  }, [bankList.length]);

  let sortedData = returnSortedTotalBalancesArray(
    totalTableСolumns,
    free2exBalancesArray,
    totalBanksBalancesArray,
    totalLpsBalancesArray,
    totalForeignBanksBalancesArray
  );

  let totalSymbolsArray: string[] = [];

  if (sortedData && sortedData.length) {
    //получение массива всех валют, в которых есть счета в банках
    sortedData.forEach((element) => {
      element.map((item) => totalSymbolsArray.push(item.symbol));
    });
  }

  let sortedDataWithUSD = sortedData.map((group) => {
    let totalInUSD = returnTotalBalanceInUSD(group, quotes, rates.result);
    for(let asset of totalInUSD) {
      if(quotes.length) {
        if(asset.amount === "not_found") {
          errorAssets.push(asset.symbol)
        }
      }
    }
    return totalInUSD
  });

  let totalSymbolsArrayUnique = [...new Set(totalSymbolsArray)]; //удаление дубликатов валют
  
  let sortedAllSymbols = sortSymbolsArray(
    totalSymbolsArrayUnique,
    totalTableCurrencySettingsArray,
    errorAssets
  );

  let filterCurrencyArray = returnFilterCurrencyArray(selectedFilter);

  if (filterCurrencyArray.length > 0) {
    sortedAllSymbols = filterCurrencyArray;

    sortedDataWithUSD = sortedData.map((group) => {
      let filteredGroup = group.filter((item) =>
        filterCurrencyArray.includes(item.symbol)
      );
      return returnTotalBalanceInUSD(filteredGroup, quotes, rates.result);
    });
  }

  let totalBalances = returnTotalBalance(sortedData, sortedAllSymbols);
  let quotesFormatted = returnFormedCCQuotesArray(quotes);
  let ratesFormatted = returnFormedFiatQuotesArray(rates.result);
  let totalBalancesInUSD = returnTotalBalanceInUSD(
    totalBalances,
    quotes,
    rates.result
  );

  for(let asset of totalBalancesInUSD) {
    if(quotes.length) {
      if(asset.amount === "not_found") {
        errorAssets.push(asset.symbol)
      }
    }
  }

  useEffect(() => {
    let symbols = sortedAllSymbols
      .filter(
        (cur) =>
          !fiatCurrencyArrayToFilter.includes(cur) && cur !== "USDT (TRC20)"
      )
      .join(",");
    if (symbols.length > 0 && lpsBalancesArray.length > 7) {
      dispatch(
        fetchCryptoQuotesStart({
          symbols: symbols,
          accessToken: token,
        })
      );
    }
  }, [sortedAllSymbols.length, lpsBalancesArray.length]);

  if (lpBalancesStatus === "pending" || banksBalancesStatus === "pending") {
    return <Spinner width={100} height={100} marginTop="200px"></Spinner>;
  }

  let timestampt = new Date().getTime();

  return isAuth ? (
    <>
      <Header selectedMenu="Treasury"></Header>
      <TabsMenu
        linkEnum={LinkButtons}
        links={LINKS_LIST}
        selectedLink={selectedLink}
        onLinkClick={() => setSelectedLink}
      />
      <section className={styles.main}>
        <div className={`container ${styles.mainContainer}`}>
          <div ref={targetRef}>
            <h2 className={styles.title}>Total</h2>
            <Filter
              selectedRadio={selectedFilter}
              onRadioChange={radioHandler}
            />
            <TableTotal
              targetRef={targetRef}
              tableName={"Total " + selectedFilter}
              date={getDateOfUpdate(timestampt)}
              dateTime={getDateAndTimeOfUpdate(timestampt)}
              quotesFormatted={quotesFormatted}
              ratesFormatted={ratesFormatted}
              totalBalancesInUSD={totalBalancesInUSD}
              sortedData={sortedData}
              sortedAllSymbols={sortedAllSymbols}
              totalBalances={totalBalances}
              quotes={quotes}
              selectedFilter={selectedFilter}
              sortedDataWithUSD={sortedDataWithUSD}
              errorAssets={errorAssets}
              token={token}
            ></TableTotal>
          </div>
        </div>
      </section>
    </>
  ) : (
    <Navigate to={AppPages.LOGIN} />
  );
};
