import { takeEvery, takeLatest } from "@redux-saga/core/effects";
import { put, call } from "typed-redux-saga";
import { actions } from "./utilsSlice";
import { UtilsApi } from "./utilsApi";

export function* utilsListSaga() {
  yield takeLatest(actions.fetchUtilsListStart, function* (action) {
    try {
      const result = yield* call(UtilsApi.getUtils, action.payload);
      yield* put(actions.fetchUtilsListSuccess(result));
    } catch (e) {
      if (e instanceof Error) {
        yield* put(actions.fetchUtilsListFailure(e.message));
      }
    }
  });
}

export function* utilStateSaga() {
  yield takeEvery(actions.fetchUtilStateStart, function* (action) {
    try {
      const result = yield* call(UtilsApi.getUtilState, action.payload);
      yield* put(actions.fetchUtilStateSuccess(result));
    } catch (e) {
      if (e instanceof Error) {
        yield* put(actions.fetchUtilStateFailure(e.message));
      }
    }
  });
}
