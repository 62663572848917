import { TGetBalancesOfBankPayload, TGetListOfBanksPayload } from "./types";

export namespace BankApi {
  export async function getBalance(payload: TGetBalancesOfBankPayload) {
    const server = `/api/treasury/Banks/${payload.bankId}`;
    let headers = new Headers({
      Authorization: `Bearer ${payload.accessToken}`,
      "content-type": "application/json",
    });
    try {
      const response = await fetch(server, {
        method: "GET",
        headers: headers,
      });

      if (response.status !== 200) {
        const errorText = await response.text();
        throw new Error(errorText);
      }
      const data = await response.json();
      return data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
  export async function getBanks(payload: TGetListOfBanksPayload) {
    const server = "/api/treasury/Banks";
    let headers = new Headers({
      Authorization: `Bearer ${payload.accessToken}`,
      "content-type": "application/json",
    });
    try {
      const response = await fetch(server, {
        method: "GET",
        headers: headers,
      });

      if (response.status !== 200) {
        const errorText = await response.text();
        throw new Error(errorText);
      }
      const data = await response.json();
      return data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
}
