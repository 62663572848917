import { takeLatest } from "@redux-saga/core/effects";
import { put, call } from "typed-redux-saga";
import { actions } from "./menuSlice";
import { MenuApi } from "./menuApi";

export function* menuSaga() {
  yield takeLatest(actions.fetchMenuStart, function* (action) {
    try {
      const result = yield* call(MenuApi.getMenu, action.payload);
      yield* put(actions.fetchMenuSuccess({ menus: result }));
    } catch (e) {
      if (e instanceof Error) {
        yield* put(actions.fetchMenuFailure(e.message));
      }
    }
  });
}
