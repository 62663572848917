import { TClearRatePayload, TGetPairsPayload, TGetRatePayload, TSetRatePayload } from "./types";

export namespace DealingRatesApi {
  export async function getRate(payload: TGetRatePayload) {
    let server = `/api/dealing/rates/${payload.pair}`;
    let headers = new Headers({
      Authorization: `Bearer ${payload.accessToken}`,
      "content-type": "application/json",
    });
    try {
      const response = await fetch(server, {
        method: "GET",
        headers: headers,
      });

      let ratesData;
      if (response.status === 200) {
        ratesData = await response.text();
      }

      const historyResponse = await fetch(
        `/api/dealing/archive/${payload.pair?.toUpperCase()}?amount=10`,
        {
          method: "GET",
          headers: headers,
        }
      );

      let historyResult;
      if (historyResponse.status === 200) {
        historyResult = await historyResponse.json();
      }

      return {
        currentRates: ratesData ? JSON.parse(ratesData) : undefined,
        history: historyResult,
      };
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
  export async function setRate(payload: TSetRatePayload) {
    const server = `/api/dealing/rates/${payload.pair}`;
    let headers = new Headers({
      Authorization: `Bearer ${payload.accessToken}`,
      "content-type": "application/json",
    });
    try {
      const response = await fetch(server, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          currentAskRate: payload.askRate,
          currentBidRate: payload.bidRate,
          currentAskMarkup: payload.askMarkup,
          currentBidMarkup: payload.bidMarkup
        }),
      });

      if (response.status !== 200) {
        const errorText = await response.text();
        throw new Error(errorText);
      }

      const setToHistoryResponse = await fetch("/api/dealing/archive", {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          symbol: payload.pair?.toUpperCase(),
          bid: payload?.bidRate,
          ask: payload?.askRate,
          bidMarkup: payload?.bidMarkup,
          askMarkup: payload.askMarkup,
          createDate: payload?.createDate,
          user: payload?.user,
        }),
      });

      if (setToHistoryResponse.status !== 200) {
        const errorText = await response.text();
        throw new Error(errorText);
      }

      const historyResponse = await fetch(
        `/api/dealing/archive/${payload.pair?.toUpperCase()}?amount=10`,
        {
          method: "GET",
          headers: headers,
        }
      );

      let historyResult;
      if (historyResponse.status === 200) {
        historyResult = await historyResponse.json();
      }
      const data = {
        currentRates: { ask: payload.askRate, bid: payload?.bidRate, askMarkup: payload?.askMarkup, bidMarkup: payload?.bidMarkup },
        history: historyResult,
      };
      return data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
  export async function getPairs(payload: TGetPairsPayload) {
    let server = `/api/dealing/pairs`;
    let headers = new Headers({
      Authorization: `Bearer ${payload.accessToken}`,
      "content-type": "application/json",
    });
    try {
      const response = await fetch(server, {
        method: "GET",
        headers: headers,
      });

      if (response.status !== 200) {
        const errorText = await response.text();
        throw new Error(errorText);
      }

      const data = await response.json();
      return data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  export async function clearPairs(payload: TClearRatePayload) {
    let server = `/api/dealing/rates/${payload.pair}`;
    let headers = new Headers({
      Authorization: `Bearer ${payload.accessToken}`,
      "content-type": "application/json",
    });
    try {
      const response = await fetch(server, {
        method: "DELETE",
        headers: headers,
      });

      if (response.status !== 200) {
        const errorText = await response.text();
        throw new Error(errorText);
      }

      const data = await response.text();
      return data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
}
