import { useEffect, useState } from "react";
import styles from "./RateInput.module.css";
import { FormButton } from "../../buttons/form-button/FormButton";
type RateInputProps = {
  askRate: string;
  bidRate: string;
  bidMarkup: string;
  askMarkup: string;
  pair: string;
  handleClick: (
    newAskRate: string,
    newBidRate: string,
    newAskMarkup: string,
    newBidMarkup: string,
    pair: string
  ) => void;
};

export const RateInput: React.FC<RateInputProps> = ({
  askRate,
  bidRate,
  bidMarkup,
  askMarkup,
  pair,
  handleClick,
}) => {
  const [newBidRate, setNewBidRate] = useState<string>(bidRate);
  const [clearedNewBidRate, setClearedNewBidRate] = useState(false);
  const [newBidRateInputActive, setNewBidRateInputActive] = useState(false);
  const [newAskRate, setNewAskRate] = useState<string>(askRate);
  const [clearedNewAskRate, setClearedNewAskRate] = useState(false);
  const [newAskRateInputActive, setNewAskRateInputActive] = useState(false);

  const [newBidMarkup, setNewBidMarkup] = useState<string>(bidMarkup);
  const [clearedNewBidMarkup, setClearedNewBidMarkup] = useState(false);
  const [newBidMarkupInputActive, setNewBidMarkupInputActive] = useState(false);
  const [newAskMarkup, setNewAskMarkup] = useState<string>(askMarkup);
  const [clearedNewAskMarkup, setClearedNewAskMarkup] = useState(false);
  const [newAskMarkupInputActive, setNewAskMarkupInputActive] = useState(false);

  useEffect(() => {
    setNewBidRate(bidRate);
  }, [bidRate]);

  useEffect(() => {
    setNewAskRate(askRate);
  }, [askRate]);

  useEffect(() => {
    setNewAskMarkup(askMarkup);
  }, [askMarkup]);

  useEffect(() => {
    setNewBidMarkup(bidMarkup);
  }, [bidMarkup]);

  return (
    <form className={styles.form}>
      <div className={styles.pairBlock}>
      <div className={styles.divInput}>
        <p className={styles.label}>
          New ASK
        </p>
        <input
          className={styles.input}
          id="newAskRate"
          type="newAskRate"
          value={clearedNewAskRate ? "" : newAskRate}
          onChange={(e) => {
            setClearedNewAskRate(false);
            setNewAskRate(e.target.value);
            setNewAskRateInputActive(true);
          }}
        />
        <svg
          role="button"
          onClick={(e) => {
            setClearedNewAskRate(true);
            setNewAskRateInputActive(false);
          }}
          className={
            newAskRateInputActive ? styles.crossSvg : styles.crossSvgHidden
          }
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M24.0002 30.6668C20.3183 30.6668 17.3335 27.682 17.3335 24.0002C17.3335 20.3183 20.3183 17.3335 24.0002 17.3335C27.682 17.3335 30.6668 20.3183 30.6668 24.0002C30.6668 27.682 27.682 30.6668 24.0002 30.6668ZM24.0002 23.0574L22.5859 21.6431C22.3256 21.3828 21.9035 21.3828 21.6431 21.6431C21.3828 21.9035 21.3828 22.3256 21.6431 22.5859L23.0574 24.0002L21.6431 25.4144C21.3828 25.6747 21.3828 26.0968 21.6431 26.3572C21.9035 26.6175 22.3256 26.6175 22.5859 26.3572L24.0002 24.943L25.4144 26.3572C25.6747 26.6175 26.0968 26.6175 26.3572 26.3572C26.6175 26.0968 26.6175 25.6747 26.3572 25.4144L24.943 24.0002L26.3572 22.5859C26.6175 22.3256 26.6175 21.9035 26.3572 21.6431C26.0968 21.3828 25.6747 21.3828 25.4144 21.6431L24.0002 23.0574Z"
            fill="#848790"
          />
        </svg>
        </div>
        <div className={styles.divInput}>
        <p className={styles.label}>
          New ASK Markup, %
        </p>
        <input
          className={styles.input}
          id="newAskMarkup"
          type="newAskMarkup"
          value={clearedNewAskMarkup ? "" : newAskMarkup}
          onChange={(e) => {
            setClearedNewAskMarkup(false);
            setNewAskMarkup(e.target.value);
            setNewAskMarkupInputActive(true);
          }}
        />
        <svg
          role="button"
          onClick={(e) => {
            setClearedNewAskMarkup(true);
            setNewAskMarkupInputActive(false);
          }}
          className={
            newAskMarkupInputActive ? styles.crossSvg : styles.crossSvgHidden
          }
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M24.0002 30.6668C20.3183 30.6668 17.3335 27.682 17.3335 24.0002C17.3335 20.3183 20.3183 17.3335 24.0002 17.3335C27.682 17.3335 30.6668 20.3183 30.6668 24.0002C30.6668 27.682 27.682 30.6668 24.0002 30.6668ZM24.0002 23.0574L22.5859 21.6431C22.3256 21.3828 21.9035 21.3828 21.6431 21.6431C21.3828 21.9035 21.3828 22.3256 21.6431 22.5859L23.0574 24.0002L21.6431 25.4144C21.3828 25.6747 21.3828 26.0968 21.6431 26.3572C21.9035 26.6175 22.3256 26.6175 22.5859 26.3572L24.0002 24.943L25.4144 26.3572C25.6747 26.6175 26.0968 26.6175 26.3572 26.3572C26.6175 26.0968 26.6175 25.6747 26.3572 25.4144L24.943 24.0002L26.3572 22.5859C26.6175 22.3256 26.6175 21.9035 26.3572 21.6431C26.0968 21.3828 25.6747 21.3828 25.4144 21.6431L24.0002 23.0574Z"
            fill="#848790"
          />
        </svg>
        </div>
        </div>
        <div className={styles.pairBlock}>
        <div className={styles.divInput}>
          <p className={styles.label}>
            New BID
          </p>
          <input
            className={styles.input}
            id="newBidRate"
            type="newBidRate"
            value={clearedNewBidRate ? "" : newBidRate}
            onChange={(e) => {
              setClearedNewBidRate(false);
              setNewBidRate(e.target.value);
              setNewBidRateInputActive(true);
            }}
          />
          <svg
            role="button"
            onClick={(e) => {
              setClearedNewBidRate(true);
              setNewBidRateInputActive(false);
            }}
            className={
              newBidRateInputActive ? styles.crossSvg : styles.crossSvgHidden
            }
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M24.0002 30.6668C20.3183 30.6668 17.3335 27.682 17.3335 24.0002C17.3335 20.3183 20.3183 17.3335 24.0002 17.3335C27.682 17.3335 30.6668 20.3183 30.6668 24.0002C30.6668 27.682 27.682 30.6668 24.0002 30.6668ZM24.0002 23.0574L22.5859 21.6431C22.3256 21.3828 21.9035 21.3828 21.6431 21.6431C21.3828 21.9035 21.3828 22.3256 21.6431 22.5859L23.0574 24.0002L21.6431 25.4144C21.3828 25.6747 21.3828 26.0968 21.6431 26.3572C21.9035 26.6175 22.3256 26.6175 22.5859 26.3572L24.0002 24.943L25.4144 26.3572C25.6747 26.6175 26.0968 26.6175 26.3572 26.3572C26.6175 26.0968 26.6175 25.6747 26.3572 25.4144L24.943 24.0002L26.3572 22.5859C26.6175 22.3256 26.6175 21.9035 26.3572 21.6431C26.0968 21.3828 25.6747 21.3828 25.4144 21.6431L24.0002 23.0574Z"
              fill="#848790"
            />
          </svg>
        </div>
        <div className={styles.divInput}>
        <p className={styles.label}>
          New BID Markup, %
        </p>
        <input
          className={styles.input}
          id="newBidMarkup"
          type="newBidMarkup"
          value={clearedNewBidMarkup ? "" : newBidMarkup}
          onChange={(e) => {
            setClearedNewBidMarkup(false);
            setNewBidMarkup(e.target.value);
            setNewBidMarkupInputActive(true);
          }}
        />
        <svg
          role="button"
          onClick={(e) => {
            setClearedNewBidMarkup(true);
            setNewBidMarkupInputActive(false);
          }}
          className={
            newBidMarkupInputActive ? styles.crossSvg : styles.crossSvgHidden
          }
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M24.0002 30.6668C20.3183 30.6668 17.3335 27.682 17.3335 24.0002C17.3335 20.3183 20.3183 17.3335 24.0002 17.3335C27.682 17.3335 30.6668 20.3183 30.6668 24.0002C30.6668 27.682 27.682 30.6668 24.0002 30.6668ZM24.0002 23.0574L22.5859 21.6431C22.3256 21.3828 21.9035 21.3828 21.6431 21.6431C21.3828 21.9035 21.3828 22.3256 21.6431 22.5859L23.0574 24.0002L21.6431 25.4144C21.3828 25.6747 21.3828 26.0968 21.6431 26.3572C21.9035 26.6175 22.3256 26.6175 22.5859 26.3572L24.0002 24.943L25.4144 26.3572C25.6747 26.6175 26.0968 26.6175 26.3572 26.3572C26.6175 26.0968 26.6175 25.6747 26.3572 25.4144L24.943 24.0002L26.3572 22.5859C26.6175 22.3256 26.6175 21.9035 26.3572 21.6431C26.0968 21.3828 25.6747 21.3828 25.4144 21.6431L24.0002 23.0574Z"
            fill="#848790"
          />
        </svg>
        </div>
        </div>
        <FormButton
          className={styles.button}
          onClick={(e) => {
            if (newAskRate?.length || newBidRate?.length || newAskMarkup?.length || newBidMarkup?.length) {
              handleClick(newAskRate, newBidRate, newAskMarkup, newBidMarkup, pair);
              e.preventDefault();
            }
          }}
        >
          Set rate
        </FormButton>
    </form>
  );
};
