export enum FetchStatus {
  FAILURE = "failure",
  PENDING = "pending",
  SUCCESS = "success",
}

export type TGetAllNbrbRatesPayload = {
  accessToken: string | null;
};

export type TGetSelectedNbrbRatesPayload = {
  accessToken: string | null
  currency:string;
};


export type TResultOfNbrbGetRatesAPI = Array<{
  Cur_ID: number;
  Date: string;
  Cur_Abbreviation: string;
  Cur_Scale: number;
  Cur_Name: string;
  Cur_OfficialRate: number;
}>;

export type TRatesFormatted = Array<{
  symbol: string;
  quote: string;
  date: string;
  quantity: number;
}>