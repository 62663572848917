import styles from "./ServiceParams.module.css";
type ServiceParamsProps = {
  request: {
    id: string;
    name: string;
    descr: string;
    url: string;
    method: string;
  };
  onClick:()=>void;
};

export const ServiceParams: React.FC<ServiceParamsProps> = ({ request, onClick }) => {
  return (
    <article className={styles.serviceItem} onClick={onClick}>
        <h2 className={styles.id}>Id: {request.id}</h2>
        <p>Descr: {request.descr}</p>
        <p>Url: {request.url}</p>
        <p>Method: {request.method}</p>
    </article>
  );
};
