import { takeLatest } from "@redux-saga/core/effects";
import { put, call } from "typed-redux-saga";
import { actions } from "./loginSlice";
import { loginApi } from "./loginApi";

export function* loginSaga() {
  yield takeLatest(actions.fetchLoginStart, function* (action) {
    try {
      const result = yield* call(loginApi.login, action.payload);
      yield* put(actions.fetchLoginSuccess({ login: result }));
    } catch (e) {
      if (e instanceof Error) {
        yield* put(actions.fetchLoginFailure(e.message));
      }
    }
  });
}

export function* loginSecretSaga() {
  yield takeLatest(actions.fetchLoginSecretStart, function* (action) {
    try {
      const result = yield* call(loginApi.loginBySecret, action.payload);
      yield* put(actions.fetchLoginSecretSuccess({ login: result }));
    } catch (e) {
      if (e instanceof Error) {
        yield* put(actions.fetchLoginSecretFailure(e.message));
      }
    }
  });
}