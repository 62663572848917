import { all } from "redux-saga/effects";
import { pairClearRateSaga, pairListSaga, pairRateSaga, pairSetRateSaga } from "./dealingRatesSaga";
import { actions } from "./dealingRatesSlice";
export { dealingRatesReducer } from "./dealingRatesSlice";
export const {
  fetchPairListStart,
  fetchPairListSuccess,
  fetchPairListFailure,
  clearPairListState,
  fetchPairRateStart,
  fetchPairRateSuccess,
  fetchPairRateFailure,
  clearPairRateState,
  fetchSetPairRateStart,
  fetchSetPairRateSuccess,
  fetchSetPairRateFailure,
  clearSetPairRateState,
  fetchClearPairRateStart,
  fetchClearPairRateSuccess,
  fetchClearPairRateFailure,
} = actions;

export function* dealingRatesSagas() {
  yield all([pairListSaga(), pairRateSaga(), pairSetRateSaga(), pairClearRateSaga()]);
}