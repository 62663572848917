import { takeLatest } from "@redux-saga/core/effects";
import { put, call } from "typed-redux-saga";
import { actions } from "./ratesSlice";
import { RatesApi } from "./ratesApi";

export function* allNbrbRatesSaga() {
  yield takeLatest(actions.fetchAllNbrbRatesStart, function* (action) {
    try {
      const result = yield* call(RatesApi.getAllNbrbRates, action.payload);
      yield* put(actions.fetchAllNbrbRatesListSuccess({ nbrbAllRatesList: result }));
    } catch (e) {
      if (e instanceof Error) {
        yield* put(actions.fetchAllNbrbRatesListFailure(e.message));
      }
    }
  });
}

export function* selectedNbrbRatesSaga() {
  yield takeLatest(actions.fetchNbrbRateStart, function* (action) {
    try {
      const result = yield* call(RatesApi.getSelectedNbrbRate, action.payload);
      yield* put(actions.fetchNbrbRateSuccess({ nbrbRate: result }));
    } catch (e) {
      if (e instanceof Error) {
        yield* put(actions.fetchAllNbrbRatesListFailure(e.message));
      }
    }
  });
}
