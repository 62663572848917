import styles from "./PairItem.module.css";
type PairItemProps = {
  pair: string;
  onClick: () => void;
};

export const PairItem: React.FC<PairItemProps> = ({ pair, onClick }) => {
  return (
    <li key={pair} className={styles.pairItem} onClick={onClick}>
      <div>
        <p className={styles.name}>{pair}</p>
      </div>
    </li>
  );
};
