import { createSlice } from "@reduxjs/toolkit";
import { FetchStatus, TClearRatePayload, TGetPairsPayload, TGetRatePayload, TSetRatePayload } from "./types";

const dealingRatesSlice = createSlice({
  name: "dealing_rates",
  initialState: {
    pairList: [],
    pairRate: null,
    history: null,
    fetchPairListStatus:null,
    fetchPairRateStatus: null,
    fetchSetPairRateStatus:null,
    fetchClearPairRateStatus:null
  } as {
    pairList: Array<string>,
    pairRate: {ask: string | null, bid: string | null, askMarkup: string, bidMarkup:string} | null,
    history: any,
    fetchPairListStatus:FetchStatus | null,
    fetchPairRateStatus: FetchStatus  | null,
    fetchSetPairRateStatus:FetchStatus  | null,
    fetchClearPairRateStatus:FetchStatus  | null,
  },
  reducers: {
    fetchPairListStart: (
      state,
      action: { payload: TGetPairsPayload }
    ) => {
      state.fetchPairListStatus = FetchStatus.PENDING;
    },
    fetchPairListSuccess: (
      state,
      action: { payload: { pairs: Array<string> }}
    ) => {
      state.pairList = action.payload.pairs;
      state.fetchPairListStatus = FetchStatus.SUCCESS;
    },
    fetchPairListFailure: (state, action) => {
      state.fetchPairListStatus = FetchStatus.FAILURE;
      console.error("Loading Failure", action.payload);
    },
    clearPairListState: (state) => {
      state.pairList = [];
      state.fetchPairListStatus = null;
    },

    fetchPairRateStart: (
      state,
      action: { payload: TGetRatePayload }
    ) => {
      state.fetchPairRateStatus = FetchStatus.PENDING;
    },
    fetchPairRateSuccess: (
      state,
      action: { payload:{currentRates: {ask: string, bid: string, askMarkup: string, bidMarkup:string}, history: any} }
    ) => {
      state.pairRate = action.payload.currentRates;
      state.history = action.payload.history;
      state.fetchPairRateStatus = FetchStatus.SUCCESS;
    },
    fetchPairRateFailure: (state, action) => {
      state.fetchPairRateStatus = FetchStatus.FAILURE;
      console.error("Loading Failure", action.payload);
    },
    clearPairRateState: (state) => {
      state.pairRate = null;
      state.fetchPairRateStatus = null;
    },
    fetchSetPairRateStart: (
      state,
      action: { payload: TSetRatePayload }
    ) => {
      state.fetchSetPairRateStatus = FetchStatus.PENDING;
    },
    fetchSetPairRateSuccess: (
      state,
      action: { payload:{currentRates: {ask: string, bid: string, askMarkup: string, bidMarkup:string}, history: any}} 
    ) => {
      state.pairRate = action.payload.currentRates;
      state.history = action.payload.history;
      state.fetchSetPairRateStatus = FetchStatus.SUCCESS;
    },
    fetchSetPairRateFailure: (state, action) => {
      state.fetchSetPairRateStatus = FetchStatus.FAILURE;
      console.error("Loading Failure", action.payload);
    },
    clearSetPairRateState: (state) => {
      state.fetchSetPairRateStatus = null;
      state.history = null;
    },

    fetchClearPairRateStart: (
      state,
      action: { payload: TClearRatePayload }
    ) => {
      state.fetchSetPairRateStatus = FetchStatus.PENDING;
    },
    fetchClearPairRateSuccess: (
      state,
      action 
    ) => {
      if(state.pairRate?.ask) {
        state.pairRate.ask = null
      }
      if(state.pairRate?.bid) {
        state.pairRate.bid = null
      }
      state.fetchSetPairRateStatus = FetchStatus.SUCCESS;
    },
    fetchClearPairRateFailure: (state, action) => {
      state.fetchSetPairRateStatus = FetchStatus.FAILURE;
      console.error("Loading Failure", action.payload);
    }
  },
});

export const { actions } = dealingRatesSlice;
export const dealingRatesReducer = dealingRatesSlice.reducer;
