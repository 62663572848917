/* eslint-disable react-hooks/exhaustive-deps */
import styles from "./LpPage.module.css";
import { Header } from "../../components/header/Header";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useEffect, useRef, useState } from "react";
import {
  clearlpsState,
  fetchlpsBalancesStart,
  fetchLpsListStart,
} from "../../api/lps";
import { TableLP } from "../../components/table/table-lp/TableLP";
import { Navigate } from "react-router-dom";
import { AppPages, LinkButtons } from "../../types";
import {
  checkIsAuth,
  returnFormedCCQuotesArray,
  returnFormedFiatQuotesArray,
  returnTotalBalanceInUSD,
  sortBalancesArrayByLPName,
  sortSymbolsArray,
  getDateOfUpdate,
  getDateAndTimeOfUpdate,
} from "../../helpers";
import { TabsMenu } from "../../components/tabs-menu/TabsMenu";
import { Spinner } from "../../components/spinner/Spinner";
import { Filter } from "../../components/filter/Filter";
import {
  fiatCurrencyArrayToFilter,
  lpCurrencySettingsArray,
  returnFilterCurrencyArray,
} from "../../settings";
import { fetchCryptoQuotesStart } from "../../api/cryptoQuotes";
import { TLPItem } from "../../../functions/lps/lpTypes";
import { fetchAllNbrbRatesStart } from "../../api/rates";

const LINKS_LIST = Object.values(LinkButtons);

type LpPageProps = {};

export const LpPage: React.FC<LpPageProps> = () => {
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [selectedLink, setSelectedLink] = useState(LinkButtons.LPS);
  const lpBalances = useAppSelector((state) => state.lps.lpsBalances);
  const lpBalancesStatus = useAppSelector(
    (state) => state.lps.fetchBalanceStatus
  );
  // const isLocked = useAppSelector((state) => state.lps.isLocked);
  const totalBalances = useAppSelector((state) => state.lps.totalValuesArray);
  const lpList = useAppSelector((state) => state.lps.lpsList);
  const lpListStatus = useAppSelector((state) => state.lps.fetchLpsListStatus);
  const quotes = useAppSelector((state) => state.cryptoQuotes.cryptoQuotes);
  const rates = useAppSelector((state) => state.rates.nbrbAllRatesList);
  const targetRef = useRef(null);
  let token = localStorage.getItem("accessToken");
  token = token ? JSON.parse(token) : ""
  const dispatch = useAppDispatch();
  const [isAuth, setIsAuth] = useState(true);
  const radioHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedFilter(event.target.value);
  };

  useEffect(() => {
    dispatch(clearlpsState());
    dispatch(fetchLpsListStart({ accessToken: token }));
    dispatch(fetchAllNbrbRatesStart({ accessToken: token }));
    checkIsAuth().then((response) => setIsAuth(response));
  }, []);

  useEffect(() => {
    if (lpList.length > 0) {
      for (let lp of lpList) {
        if (lp.subLpIds) {
          for (let subLpId of lp.subLpIds) {
            dispatch(
              fetchlpsBalancesStart({
                lpId: lp.lpId,
                subLpId: subLpId,
                accessToken: token,
              })
            );
          }
        } else {
          dispatch(
            fetchlpsBalancesStart({ lpId: lp.lpId, accessToken: token })
          );
        }
      }
      // dispatch(
      //   fetchlpsPABalancesStart({ lpId: "fxopen_id", accessToken: token })
      // );
    }
  }, [lpList.length]);

  let lpAllSymbolsArray: string[] = [];
  let lpNamesArray: string[] = lpList.map((lp) => lp.lpName);
  let sortedData: TLPItem[] = [];
  let errorAssets:string[] = []

  if (lpBalances && lpBalances.length) {
    sortedData = sortBalancesArrayByLPName(lpNamesArray, lpBalances); //отсортировать массив с LP по столбцам-именам лп
    sortedData.forEach((lp) => {
      lp.lpRests.forEach((item) => lpAllSymbolsArray.push(item.symbol));
    });

    sortedData = sortedData.map((lp) => {
      let sumInUSD = returnTotalBalanceInUSD(lp.lpRests, quotes, rates.result);
      if(quotes.length) {
        for(let curr of sumInUSD) {
          if(curr.amount === "not_found") {
            errorAssets.push(curr.symbol)
          }
        }
      }
      let totalSumInUSD = sumInUSD.reduce(
        (prev, curr) => prev + (curr.amount !== "not_found" ? Number(curr.amount) : 0),
        0
      );
      return { ...lp, sumInUSD: totalSumInUSD };
    });
  }

  let lpSymbolsArrayUnique = [...new Set(lpAllSymbolsArray)]; //удаление дубликатов валют
  let sortedLpSymbols = sortSymbolsArray(lpSymbolsArrayUnique, lpCurrencySettingsArray, errorAssets);
  let filterCurrencyArray = returnFilterCurrencyArray(selectedFilter);

  if(filterCurrencyArray.length > 0) {
    sortedLpSymbols = filterCurrencyArray;
    sortedData = sortedData.map((lp) => {
      let filteredRest = lp.lpRests.filter((item) => sortedLpSymbols.includes(item.symbol))
      let sumInUSD = returnTotalBalanceInUSD(filteredRest, quotes, rates.result);
      let totalSumInUSD = sumInUSD.reduce(
        (prev, curr) => prev + (curr.amount !== "not_found" ? Number(curr.amount) : 0),
        0
      );
      return { ...lp, sumInUSD: totalSumInUSD };
    });
  }

  useEffect(() => {
    let symbols = sortedLpSymbols
      .filter(
        (cur) =>
          !fiatCurrencyArrayToFilter.includes(cur) && cur !== "USDT (TRC20)"
      )
      .join(",");
    if (symbols.length > 0 && lpBalances.length > 10) {
      dispatch(
        fetchCryptoQuotesStart({
          symbols: symbols,
          accessToken: token,
        })
      );
    }
  }, [sortedLpSymbols.length, lpBalances.length]);

  let quotesFormatted = returnFormedCCQuotesArray(quotes);
  let ratesFormatted = returnFormedFiatQuotesArray(rates.result);
  let totalBalancesInUSD = returnTotalBalanceInUSD(
    totalBalances,
    quotes,
    rates.result
  );

  if (lpBalancesStatus === "pending" || lpListStatus === "pending") {
    return <Spinner width={100} height={100} marginTop="200px"></Spinner>;
  }

  let timestampt = new Date().getTime();

  return isAuth ? (
    <>
      <Header selectedMenu="Treasury"></Header>
      <TabsMenu
        linkEnum={LinkButtons}
        links={LINKS_LIST}
        selectedLink={selectedLink}
        onLinkClick={() => setSelectedLink}
      />
      <section className={styles.main}>
        <div className={`container ${styles.mainContainer}`}>
          <div ref={targetRef}>
            <h2 className={styles.title}>LPs</h2>
            <Filter
              selectedRadio={selectedFilter}
              onRadioChange={radioHandler}
            />
            <TableLP
              targetRef={targetRef}
              tableName={"LP " + selectedFilter}
              date={getDateOfUpdate(timestampt)}
              dateTime={getDateAndTimeOfUpdate(timestampt)}
              quotesFormatted={quotesFormatted}
              ratesFormatted={ratesFormatted}
              sortedLpSymbols={sortedLpSymbols}
              lpNamesArray={lpNamesArray}
              totalBalances={totalBalances}
              sortedData={sortedData}
              totalBalancesInUSD={totalBalancesInUSD}
              quotes={quotes}
              selectedFilter={selectedFilter}
              errorAssets={errorAssets}
              token={token}
              // isLocked={isLocked}
              // handle2faFxopenForm={handle2faFxopenForm}
            ></TableLP>
          </div>
        </div>
      </section>
    </>
  ) : (
    <Navigate to={AppPages.LOGIN} />
  );
};
