import { useState } from "react";
import { FormButton } from "../../buttons/form-button/FormButton";
import styles from "./SaveVars.module.css";

let availableTraceProps = ["0", "1", "2"];
type SaveVarsProps = {
  varsList: string[];
  className: string
};

export const SaveVars: React.FC<SaveVarsProps> = ({ varsList, className }) => {
  const [inputValues, setInputValues] = useState(
    {} as { [key: string]: string }
  );
  const [result, setResult] = useState(false);

  return (
    <article className={`${styles.saveVarsItem} ${className}`}>      
      <h2 className={styles.h2}>VARS SAVING</h2>
      {varsList.map((vr) => {
        let savedValue = sessionStorage.getItem(vr)
        return (
          <div className={result ? styles.hidden : styles.shown}>
            <label className={styles.label}>{vr.toUpperCase()}</label>
            <input
              name={vr}
              className={styles.input}
              type="text"
              value={savedValue ? JSON.parse(savedValue) : inputValues[vr]}
              onChange={(e) => {
                if (
                  vr === "trace" &&
                  !availableTraceProps.includes(e.target.value) && e.target.value.length
                ) {
                  e.target.value = "0";
                }
                setInputValues({
                  ...inputValues,
                  [vr]: e.target.value,
                });
              }}
            />
          </div>
        );
      })}
      <div className={result ? styles.notification : styles.hidden}>
        <svg className={styles.svgDone} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#00BEB4"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/></svg>
        <p className={styles.textDone} >Vars saved</p>
        </div>
      <FormButton
        role="button"
        children="Save"
        className={result ? styles.hidden : styles.button}
        onClick={() => {
          for (let vr in inputValues) {
            sessionStorage.setItem(vr, JSON.stringify(inputValues[vr]));
            setResult(true);
            setTimeout(() => setResult(false), 500);
          }
        }}
      />
    </article>
  );
};
