import { all } from "redux-saga/effects";
import { userInfoSaga, userLogSaga} from "./userSaga";
import { actions } from "./userSlice";
export { userReducer } from "./userSlice";
export const {
  fetchUserInfoStart,
  fetchUserInfoSuccess,
  fetchUserInfoFailure,
  clearUserInfoState,
  fetchPostLogsStart,
  fetchPostLogsSuccess,
  fetchPostLogsFailure
} = actions;

export function* userSagas() {
  yield all([userInfoSaga(), userLogSaga()]);
}