import { FormButton } from "../../buttons/form-button/FormButton";
import styles from "./clearAssetsCacheBtn.module.css";

type ClearAssetsCacheBtnProps = {
  onClick: () => void;
};

export const ClearAssetsCacheBtn: React.FC<ClearAssetsCacheBtnProps> = ({
  onClick,
}) => {
  return (
    <FormButton
      children="Clear Assets cache"
      onClick={onClick}
      className={styles.clearcachebtn}
    />
  );
};
