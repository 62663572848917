import { TGetAllNbrbRatesPayload, TGetSelectedNbrbRatesPayload } from "./types";

export namespace RatesApi {
  export async function getAllNbrbRates(payload: TGetAllNbrbRatesPayload) {
    try {
      const server = `/api/rates/nbrb`;
      let headers = new Headers({
        Authorization: `Bearer ${payload.accessToken}`,
        "content-type": "application/json",
      });
      const response = await fetch(server, {
        method: "GET",
        headers: headers,
      });

      if (response.status !== 200) {
        const errorText = await response.text();
        throw new Error(errorText);
      }

      const data = await response.json();
      return data;
    } catch (e) {
      throw e;
    }
  }

  export async function getSelectedNbrbRate(payload: TGetSelectedNbrbRatesPayload) {
    try {
      const server = `/api/rates/nbrb/${payload.currency}`;
      let headers = new Headers({
        Authorization: `Bearer ${payload.accessToken}`,
        "content-type": "application/json",
      });
      const response = await fetch(server, {
        method: "GET",
        headers: headers,
      });

      if (response.status !== 200) {
        const errorText = await response.text();
        throw new Error(errorText);
      }

      const data = await response.json();
      return data;
    } catch (e) {
      throw e;
    }
  }
}
