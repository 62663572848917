import { takeLatest } from "@redux-saga/core/effects";
import { put, call } from "typed-redux-saga";
import { actions } from "./reportsSlice";
import { ReportsApi } from "./reportsApi";

export function* allReportsSaga() {
  yield takeLatest(actions.fetchReportsListStart, function* (action) {
    try {
      const result = yield* call(ReportsApi.getAllReports, action.payload);
      yield* put(actions.fetchReportsListSuccess({ allReports: result }));
    } catch (e) {
      if (e instanceof Error) {
        yield* put(actions.fetchReportsListFailure(e.message));
      }
    }
  });
}

export function* selectedReportSaga() {
  yield takeLatest(actions.fetchReportStart, function* (action) {
    try {
      const result = yield* call(ReportsApi.getSelectedReport, action.payload);
      yield* put(actions.fetchReportSuccess({ report: result }));
    } catch (e) {
      if (e instanceof Error) {
        yield* put(actions.fetchReportFailure(e.message));
      }
    }
  });
}
