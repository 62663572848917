import { TLPItem } from "../../../functions/lps/lpTypes";

export function returnTotalLpBalance(
  arrayToCalculate: Array<TLPItem>,
  currencyArray: string[]
) {
  let newArray = currencyArray.map((item) => {
    let amountArray: number[] = [];

    arrayToCalculate.forEach((lp) => {
      let findedCurrencies = lp.lpRests.filter((i) => item === i.symbol);
      if (findedCurrencies) {
        for (let findedCurrency of findedCurrencies) {
          if (Number(findedCurrency.amount) > 0) {
            amountArray.push(Number(findedCurrency.amount));
          }
          if (Number(findedCurrency.frozenAmount) > 0) {
            amountArray.push(Number(findedCurrency.frozenAmount));
          }
        }
      }
    });

    let sumAmountArray = Number(
      amountArray.reduce((prev, curr) => {
        return prev + curr;
      }, 0)
    );

    return { symbol: item, amount: sumAmountArray };
  });

  return newArray;
}
