import styles from "./ServiceItem.module.css";
type ServiceItemProps = {
  service: {
    name: string;
    descr: string;
  };
  onClick: () => void;
};

export const ServiceItem: React.FC<ServiceItemProps> = ({
  service,
  onClick,
}) => {
  return (
    <li key={service.name} className={styles.serviceItem} onClick={onClick}>
      <div>
        <p className={styles.name}>{service.name}</p>
        <p className={styles.descr}>{service.descr}</p>
      </div>
    </li>
  );
};
