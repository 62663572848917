export async function getInstance() {
  const server = "/api/test/instance";
  let headers = new Headers({
    "content-type": "text/plain",
  });
  const response = await fetch(server, {
    method: "GET",
    headers: headers,
  });
  return await response.text();
}
