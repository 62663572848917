import styles from "./TableStatement.module.css";
import { FormButton } from "../../buttons/form-button/FormButton";
import { CSVLink } from "react-csv";
import generatePDF, { Margin } from "react-to-pdf";
import { useRef } from "react";
import { Dayjs } from "dayjs";
import { getDateOfUpdate } from "../../../helpers";

type TableStatementProps = {
  statementData: Array<{
    transactionID: string;
    block: number;
    timestamp: number | string;
    symbol: string;
    fee: number;
    fromAddress: string;
    toAddress: string;
    value: number;
    direction: string;
  }>;
  // tableHeaders: string[];
  exports: boolean;
  targetRef: any;
  reportId: string;
  dateFrom: Dayjs | null;
  dateTo: Dayjs | null;
};

export const TableStatement: React.FC<TableStatementProps> = ({
  exports,
  // tableHeaders,
  statementData,
  targetRef,
  reportId,
  dateFrom,
  dateTo,
}) => {
  const inputRef = useRef(null);
  let formattedTableName = (reportId + "_" + dateFrom + "-" + dateTo)
    .replaceAll(".", "_")
    .replaceAll(",", "")
    .replaceAll(" ", "_");

  let tableHeaders = [
    "transactionID",
    "block",
    "date",
    "symbol",
    "fee",
    "from",
    "to",
    "value",
    "direction",
  ];
  let keys = [
    "transactionID",
    "block",
    "timestamp",
    "symbol",
    "fee",
    "fromAddress",
    "toAddress",
    "value",
    "direction",
  ];
  let csvData = [tableHeaders];

  statementData?.forEach((item) => {
    let clonedItem = { ...item };
    clonedItem.timestamp = clonedItem.timestamp
      ? getDateOfUpdate(clonedItem.timestamp as number)
      : "-";
    let csvRaw: any[] = [];
    for (let header of keys) {
      if (clonedItem[header as keyof typeof clonedItem]) {
        csvRaw.push(clonedItem[header as keyof typeof clonedItem].toString());
      } else {
        csvRaw.push("-");
      }
    }
    csvData.push(csvRaw);
  });

  return statementData && statementData.length ? (
    <>
      {exports ? (
        <div id="exports" className={styles.exports}>
          <FormButton
            role="button"
            children={
              <span className={styles.exportSpan}>
                <svg
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.2806 7.71938L15.0306 2.46938C14.9609 2.39975 14.8782 2.34454 14.7871 2.3069C14.6961 2.26926 14.5985 2.24992 14.5 2.25H5.5C5.10218 2.25 4.72064 2.40804 4.43934 2.68934C4.15804 2.97064 4 3.35218 4 3.75V20.25C4 20.6478 4.15804 21.0294 4.43934 21.3107C4.72064 21.592 5.10218 21.75 5.5 21.75H19C19.3978 21.75 19.7794 21.592 20.0607 21.3107C20.342 21.0294 20.5 20.6478 20.5 20.25V8.25C20.5001 8.15148 20.4807 8.05391 20.4431 7.96286C20.4055 7.87182 20.3503 7.78908 20.2806 7.71938ZM15.0306 15.5306L12.7806 17.7806C12.711 17.8504 12.6283 17.9057 12.5372 17.9434C12.4462 17.9812 12.3486 18.0006 12.25 18.0006C12.1514 18.0006 12.0538 17.9812 11.9628 17.9434C11.8717 17.9057 11.789 17.8504 11.7194 17.7806L9.46937 15.5306C9.32864 15.3899 9.24958 15.199 9.24958 15C9.24958 14.801 9.32864 14.6101 9.46937 14.4694C9.61011 14.3286 9.80098 14.2496 10 14.2496C10.199 14.2496 10.3899 14.3286 10.5306 14.4694L11.5 15.4397V11.25C11.5 11.0511 11.579 10.8603 11.7197 10.7197C11.8603 10.579 12.0511 10.5 12.25 10.5C12.4489 10.5 12.6397 10.579 12.7803 10.7197C12.921 10.8603 13 11.0511 13 11.25V15.4397L13.9694 14.4694C14.1101 14.3286 14.301 14.2496 14.5 14.2496C14.699 14.2496 14.8899 14.3286 15.0306 14.4694C15.1714 14.6101 15.2504 14.801 15.2504 15C15.2504 15.199 15.1714 15.3899 15.0306 15.5306ZM14.5 8.25V4.125L18.625 8.25H14.5Z"
                    fill="white"
                  />
                </svg>
                PDF
              </span>
            }
            className={styles.button}
            onClick={() => {
              let exports = document.getElementById("exports")!;
              exports.style.display = "none";
              generatePDF(targetRef, {
                filename: `${formattedTableName}.pdf`,
                page: {
                  margin: Margin.SMALL,
                },
              });
              exports.style.display = "flex";
            }}
          ></FormButton>

          <CSVLink
            filename={`${formattedTableName}.csv`}
            className={styles.csv}
            data={csvData}
          >
            <span className={styles.exportSpan}>
              <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.2806 7.71938L15.0306 2.46938C14.9609 2.39975 14.8782 2.34454 14.7871 2.3069C14.6961 2.26926 14.5985 2.24992 14.5 2.25H5.5C5.10218 2.25 4.72064 2.40804 4.43934 2.68934C4.15804 2.97064 4 3.35218 4 3.75V20.25C4 20.6478 4.15804 21.0294 4.43934 21.3107C4.72064 21.592 5.10218 21.75 5.5 21.75H19C19.3978 21.75 19.7794 21.592 20.0607 21.3107C20.342 21.0294 20.5 20.6478 20.5 20.25V8.25C20.5001 8.15148 20.4807 8.05391 20.4431 7.96286C20.4055 7.87182 20.3503 7.78908 20.2806 7.71938ZM15.0306 15.5306L12.7806 17.7806C12.711 17.8504 12.6283 17.9057 12.5372 17.9434C12.4462 17.9812 12.3486 18.0006 12.25 18.0006C12.1514 18.0006 12.0538 17.9812 11.9628 17.9434C11.8717 17.9057 11.789 17.8504 11.7194 17.7806L9.46937 15.5306C9.32864 15.3899 9.24958 15.199 9.24958 15C9.24958 14.801 9.32864 14.6101 9.46937 14.4694C9.61011 14.3286 9.80098 14.2496 10 14.2496C10.199 14.2496 10.3899 14.3286 10.5306 14.4694L11.5 15.4397V11.25C11.5 11.0511 11.579 10.8603 11.7197 10.7197C11.8603 10.579 12.0511 10.5 12.25 10.5C12.4489 10.5 12.6397 10.579 12.7803 10.7197C12.921 10.8603 13 11.0511 13 11.25V15.4397L13.9694 14.4694C14.1101 14.3286 14.301 14.2496 14.5 14.2496C14.699 14.2496 14.8899 14.3286 15.0306 14.4694C15.1714 14.6101 15.2504 14.801 15.2504 15C15.2504 15.199 15.1714 15.3899 15.0306 15.5306ZM14.5 8.25V4.125L18.625 8.25H14.5Z"
                  fill="white"
                />
              </svg>
              CSV{" "}
            </span>
          </CSVLink>
        </div>
      ) : null}
      <table ref={inputRef} className={styles.table}>
        <tr>
          {tableHeaders.map((header) => {
            return <th className={styles.th}>{header}</th>;
          })}
        </tr>
        {statementData.map((tr) => {
          let clonedTr = { ...tr };
          clonedTr.timestamp = clonedTr.timestamp
            ? getDateOfUpdate(clonedTr.timestamp as number)
            : "-";
          let tds = [];
          for (let header of keys) {
            if (clonedTr[header as keyof typeof clonedTr]) {
              tds.push(
                <td className={styles.td}>
                  {clonedTr[header as keyof typeof tr]}
                </td>
              );
            } else {
              tds.push(<td className={styles.td}>-</td>);
            }
          }
          return <tr>{tds}</tr>;
        })}
      </table>
    </>
  ) : null;
};
