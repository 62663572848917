export enum FetchStatus {
  FAILURE = "failure",
  PENDING = "pending",
  SUCCESS = "success",
}

export type TGetRequestsPayload = {
  accessToken: string | null;
  serviceName: string;
};

export type TServicesList = {
  status: number,
  result: Array<{
    name: string;
    descr: string;
    category: string
  }>
};

export type TServiceRequestsList = {
  vars:{
    [key:string]:string
  },
  params: Array<{
    category: string;
    id: string;
    name: string;
    descr: string;
    url: string;
    method: string;
  }>
};

export type TGetListOfSetvicesPayload = {
  accessToken: string | null;
};

export type TGetResultOfRequestPayload = {
  accessToken: string | null;
  serviceName: string;
  requestId: string;
  url: string;
  method: string;
  reqHeaders?: { [k: string]: any };
  reqBody?: { [k: string]: string };
  index: string;
};

export type TGetRequestParamsPayload = {
  accessToken: string | null;
  serviceName: string;
  requestId: string;
};

export type TRequestParams = {
  status: number,
  result: {
    name: string;
    id: string;
    descr: string;
    test: Array<{
      body: { [k: string]: any };
      headers: { [k: string]: string };
      stage: Array<{
        descr: string;
        defaults: { url: { [key: string]: any },  body: { [k: string]: any }, headers: { [k: string]: any } };
        result: {
          status: number;
          include: string;
        };
      }>;
      main: Array<{
        body: { [k: string]: any };
        headers: { [k: string]: string };
        descr: string;
        defaults: { url: { [key: string]: any },  body: { [k: string]: any }; };
        result: {
          status: number;
          include: string;
        };
      }>;
    }>;
    url: string;
    method: string;
  }
};

export type TResultOfRequest = {
  [key: string]: { status: number; result: any };
};

export type TGetLogsPayload = {
  accessToken: string | null;
  id: string;
};