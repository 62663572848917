import { createSlice } from "@reduxjs/toolkit";
import { TOutputBalanceArray } from "../../types";
import {
  returnTotalBankBalance,
  sumBankBalanceByCurrency,
} from "./bankHelpers";
import {
  TBankArray,
  FetchStatus,
  TBankSummarizedArray,
  TGetBalancesOfBankPayload,
  TGetListOfBanksPayload,
} from "./types";

const bankSlice = createSlice({
  name: "banks",
  initialState: {
    bankList: [],
    bankBalances: [],
    summarizedBalanceArray: [],
    totalValuesArray: [],
    fetchBankListStatus: null,
    fetchBalanceStatus: null,
  } as {
    bankList: Array<{
      bankName: string;
      bankId: string;
    }>;
    summarizedBalanceArray: TBankSummarizedArray;
    totalValuesArray: TOutputBalanceArray;
    bankBalances: TBankArray;
    fetchBankListStatus: FetchStatus | null;
    fetchBalanceStatus: FetchStatus | null;
  },
  reducers: {
    fetchBanksBalancesStart: (
      state,
      action: { payload: TGetBalancesOfBankPayload }
    ) => {
      state.fetchBalanceStatus = FetchStatus.PENDING;
    },
    fetchBanksBalancesSuccess: (
      state,
      action: { payload: { balances: any } }
    ) => {
      state.bankBalances.push(action.payload.balances);
      let currencyArray: string[] = [];
      state.bankBalances.forEach((bank) => {
        if (bank.bankAcounts) {
          bank.bankAcounts.forEach((item) => {
            currencyArray.push(item.symbol);
          });
        }
      });
      let uniqueCurrencyArray = [...new Set(currencyArray)];
      state.summarizedBalanceArray = sumBankBalanceByCurrency(
        state.bankBalances,
        uniqueCurrencyArray
      ); //суммирование остатков в одной валюте
      state.totalValuesArray = returnTotalBankBalance(
        state.summarizedBalanceArray,
        uniqueCurrencyArray
      ); //получение массива для столбца "Итого"
      state.fetchBalanceStatus = FetchStatus.SUCCESS;
    },
    fetchBanksBalancesFailure: (state, action) => {
      state.fetchBalanceStatus = FetchStatus.FAILURE;
      console.error("Loading Failure", action.payload);
    },
    clearBanksState: (state) => {
      state.bankBalances = [];
    },
    fetchBanksListStart: (
      state,
      action: { payload: TGetListOfBanksPayload }
    ) => {
      state.fetchBankListStatus = FetchStatus.PENDING;
    },
    fetchBanksListSuccess: (state, action: { payload: { bankList: any } }) => {
      state.bankList = action.payload.bankList;
      state.fetchBankListStatus = FetchStatus.SUCCESS;
    },
    fetchBanksListFailure: (state, action) => {
      state.fetchBankListStatus = FetchStatus.FAILURE;
      console.error("Loading Failure", action.payload);
    },
    clearBanksListState: (state) => {
      state.bankList = [];
    },
  },
});

export const { actions } = bankSlice;
export const bankReducer = bankSlice.reducer;
