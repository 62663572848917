export type TGetMenuPayload = {
  accessToken: string | null;
};

export enum FetchStatus {
  FAILURE = "failure",
  PENDING = "pending",
  SUCCESS = "success",
}

export type TMenuItems = [
  {
    menuId: string;
    menuName: string;
    menuUrl: string;
  }
];
