import styles from "./Filter.module.css";

type FilterProps = {
  selectedRadio: string;
  onRadioChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const Filter: React.FC<FilterProps> = ({selectedRadio, onRadioChange }) => {
  return (
    <div className={styles.toggle}>
      <div className={styles.toggleItem}>
        <input
          type="radio"
          id="filterCurrencyChoice1"
          name="filterCurrency"
          value="all"
          checked={selectedRadio === "all"}
          onChange={onRadioChange}
        />
        <label htmlFor="filterCurrencyChoice1">Все</label>
      </div>
      <div className={styles.toggleItem}>
        <input
          type="radio"
          id="filterCurrencyChoice2"
          name="filterCurrency"
          checked={selectedRadio === "fiat"}
          value="fiat"
          onChange={onRadioChange}
        />
        <label htmlFor="filterCurrencyChoice2">Фиат</label>
      </div>
      <div className={styles.toggleItem}>
        <input
          type="radio"
          id="filterCurrencyChoice3"
          name="filterCurrency"
          value="stablecoins"
          checked={selectedRadio === "stablecoins"}
          onChange={onRadioChange}
        />
        <label htmlFor="filterCurrencyChoice3">Стейбл</label>
      </div>
      <div className={styles.toggleItem}>
        <input
          type="radio"
          id="filterCurrencyChoice4"
          name="filterCurrency"
          value="cryptov"
          checked={selectedRadio === "cryptov"}
          onChange={onRadioChange}
        />
        <label htmlFor="filterCurrencyChoice4">КриптоВ</label>
      </div>
    </div>
  );
};
