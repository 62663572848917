import { all } from "redux-saga/effects";
import { listOfLpsSaga, lpBalanceSaga, lpPABalanceSaga, lpPALoginSaga } from "./lpsSaga";
import { actions } from "./lpsSlice";
export { lpsReducer } from "./lpsSlice";
export const {
  fetchlpsBalancesStart,
  fetchlpsBalancesSuccess,
  fetchlpsBalancesFailure,
  clearlpsState,
  fetchLpsListStart,
  fetchLpListSuccess,
  fetchLpListFailure,
  clearLpListState,
  fetchlpsPABalancesStart,
  fetchlpsPABalancesSuccess,
  fetchlpsPABalancesFailure,
  fetchFXOpenPALoginStart, 
  fetchFXOpenPALoginSuccess,
  fetchFXOpenPALoginFailure
} = actions;

export function* lpSagas() {
  yield all([lpBalanceSaga(), listOfLpsSaga(), lpPABalanceSaga(), lpPALoginSaga()]);
}