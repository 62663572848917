import { useState } from "react";
import { FormButton } from "../buttons/form-button/FormButton";
import styles from "./TwoFactorForm.module.css";

type TwoFactorFormProps = {
  handleClick: (
    login: string,
    password: string,
    code: string,
    identityType: string,
    state: string
  ) => void;
};

export const TwoFactorForm: React.FC<TwoFactorFormProps> = ({
  handleClick,
}) => {
  const [code, setCode] = useState("");
  const [clearedCode, setClearedCode] = useState(false);
  const [codeLabel, setCodeLabel] = useState(false);
  const [codeInputActive, setCodeInputActive] = useState(false);

  let LS = sessionStorage.getItem("user");
  let data: any;
  data = LS ? JSON.parse(LS) : data;
  let identityType = "GoogleAuthenticator";

  return (
    <form className={styles.form}>
      <div className={styles.divInput}>
        <p className={codeLabel ? styles.label : styles.labelHidden}>2FA code</p>
        <input
          className={styles.input}
          type="text"
          value={clearedCode ? "" : code}
          onChange={(e) =>{
            setClearedCode(false);
            setCode(e.target.value);
            setCodeLabel(true)}}
          placeholder="2FA code"
          autoComplete="one-time-code"
          inputMode="numeric"
        />
        <svg
          role="button"
          onClick={(e) => {
            setClearedCode(true);
            setCodeLabel(false);
            setCodeInputActive(false)
          }}
          className={codeInputActive ? styles.crossSvg : styles.crossSvgHidden}
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M24.0002 30.6668C20.3183 30.6668 17.3335 27.682 17.3335 24.0002C17.3335 20.3183 20.3183 17.3335 24.0002 17.3335C27.682 17.3335 30.6668 20.3183 30.6668 24.0002C30.6668 27.682 27.682 30.6668 24.0002 30.6668ZM24.0002 23.0574L22.5859 21.6431C22.3256 21.3828 21.9035 21.3828 21.6431 21.6431C21.3828 21.9035 21.3828 22.3256 21.6431 22.5859L23.0574 24.0002L21.6431 25.4144C21.3828 25.6747 21.3828 26.0968 21.6431 26.3572C21.9035 26.6175 22.3256 26.6175 22.5859 26.3572L24.0002 24.943L25.4144 26.3572C25.6747 26.6175 26.0968 26.6175 26.3572 26.3572C26.6175 26.0968 26.6175 25.6747 26.3572 25.4144L24.943 24.0002L26.3572 22.5859C26.6175 22.3256 26.6175 21.9035 26.3572 21.6431C26.0968 21.3828 25.6747 21.3828 25.4144 21.6431L24.0002 23.0574Z"
            fill="#848790"
          />
        </svg>
      </div>


      <FormButton
        className={styles.button}
        onClick={(e) => {
          handleClick(
            data.login,
            data.pass,
            data.state,
            code,
            identityType
          );
          e.preventDefault();
        }}
      >
        Confirm code
      </FormButton>
    </form>
  )
};
