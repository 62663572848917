import styles from "./Category.module.css";
type CategoryProps = {
  categoryName: string;
  onClick:()=>void;
};

export const Category: React.FC<CategoryProps> = ({ categoryName, onClick }) => {
  return (
    <article className={styles.categoryItem} onClick={onClick}>
        <h2 className={styles.categoryName}>{categoryName}</h2>
    </article>
  );
};
