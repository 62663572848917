export enum AppPages {
  LOGIN = "/login",
  LOGIN_SECRET = "/secretLogin",
  TWOFACTOR = "/2FA",
  MAIN = "/main",
  TREASURY = "/treasury",
  TREASURY_TOTAL = "/total",
  TREASURY_LPS = "/lps",
  NOT_FOUND_PAGE = "*",
  BANKS_BELARUS = "/banks_bel",
  TEST = "/support",
  WORKERS_TESTING = "/workers_testing",
  UTILS = "/utils",
  REPORTS = "/reports",
  TREASURY_REPORTS = "/treasury_reports",
  DEALING_PAGE = "/dealing",
  DEALING_RATES_PAGE = "/dealing_rates"
}

export enum LinkButtons {
  LPS = "ЛП",
  BANKS_BEL = "Банки",
  TOTAL = "Итого",
  // BANKS_WORLD = "Banks(World)",
}

export type TOutputBalanceArray = Array<{
  symbol: string;
  amount: number | string;
  frozenAmount?: number | string;
  descr?: string;
  corpAmount?: string | number;
  frozenDescr?: string;
  balanceInUSD?:number | string
}>;

export enum LinkSupportButtons {
  WORKERS_TESTING = "Workers testing",
  UTILS = "Utils"
}

export enum LinkDealingButtons {
  RATES = "Rates",
}

export enum LinkReportsButtons {
  TREASURY_REPORTS = "Treasury reports",
}