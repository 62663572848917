export type TGetRatePayload = {
  pair: string;
  accessToken: string | null;
};

export type TClearRatePayload = {
  pair: string;
  accessToken: string | null;
};

export type TSetRatePayload = {
  pair: string;
  askRate: string;
  bidRate: string;
  askMarkup: string;
  bidMarkup:string
  accessToken: string | null;
  createDate: number;
  user: string
};

export type TGetPairsPayload = {
  accessToken: string | null;
};

export enum FetchStatus {
    FAILURE = "failure",
    PENDING = "pending",
    SUCCESS = "success",
  }
  