import { FormButton } from "../../buttons/form-button/FormButton";
import styles from "./clearCacheBtn.module.css";

type ClearCacheBtnProps = {
  onClick: () => void;
};

export const ClearCacheBtn: React.FC<ClearCacheBtnProps> = ({ onClick }) => {
  return (
    <FormButton
      children="Clear CF cache"
      onClick={onClick}
      className={styles.clearcachebtn}
    />
  );
};
