import styles from "./MainPage.module.css";
import { Header } from "../../components/header/Header";
import { Navigate } from "react-router-dom";
import { AppPages } from "../../types";
import { useEffect, useState } from "react";
import { checkIsAuth } from "../../helpers";
import { useAppSelector, useAppDispatch } from "../../hooks";
import { fetchUserInfoStart } from "../../api/user";
import { getInstance } from "../../api/instance/getInstance";

type MainPageProps = {};

export const MainPage: React.FC<MainPageProps> = () => {
  const [isAuth, setIsAuth] = useState(true);
  const [instance, setInstance] = useState("")
  useEffect(() => {
    checkIsAuth().then((response) => setIsAuth(response));
  }, []);
  const userInfo = useAppSelector((state) => state.user.info);
  const dispatch = useAppDispatch();
  let token = localStorage.getItem("accessToken");
  token = token ? JSON.parse(token) : "";

  useEffect(() => {
    // dispatch(clearUserInfoState());
    dispatch(fetchUserInfoStart({ accessToken: token }));
    checkIsAuth().then((response) => setIsAuth(response));
    getInstance().then((response) => setInstance(response));
  }, [dispatch, token]);

  return isAuth ? (
    <>
      <Header></Header>
      <section className={styles.main}>
        <div className="container">
          <div className={styles.textInfo}>
            <p className={styles.textInfo}>Instance: {instance}</p>
            {userInfo ? (
              <>
             <p>Имя пользователя: {userInfo.userName.split("FX ")[1]}</p>
             <p>Тип аккаунта: {userInfo.userTypeName}</p>
             <p>Ewallet: {userInfo.mainAccountNumber}</p>
             <p>Email: {userInfo.email}</p>
             </>
            ) : null }
          </div>
        </div>
      </section>
    </>
  ) : (
    <Navigate to={AppPages.LOGIN} />
  );
};
