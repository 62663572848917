import { takeEvery, takeLatest } from "@redux-saga/core/effects";
import { put, call } from "typed-redux-saga";
import { actions } from "./lpsSlice";
import { LpApi } from "./lpApi";

export function* lpBalanceSaga() {
  yield takeEvery(actions.fetchlpsBalancesStart, function* (action) {
    try {
      const result = yield* call(LpApi.getBalance, action.payload);
      yield* put(actions.fetchlpsBalancesSuccess({ balances: result }));
    } catch (e) {
      if (e instanceof Error) {
        yield* put(actions.fetchlpsBalancesFailure(e.message));
      }
    }
  });
}

export function* lpPABalanceSaga() {
  yield takeEvery(actions.fetchlpsPABalancesStart, function* (action) {
    try {
      const result = yield* call(LpApi.getPABalance, action.payload);
      yield* put(actions.fetchlpsPABalancesSuccess({ balances: result }));
    } catch (e) {
      if (e instanceof Error) {
        yield* put(actions.fetchlpsBalancesFailure(e.message));
      }
    }
  });
}

export function* lpPALoginSaga() {
  yield takeLatest(actions.fetchFXOpenPALoginStart, function* (action) {
    try {
      const result = yield* call(LpApi.loginPAFXOpen, action.payload);
      yield* put(actions.fetchFXOpenPALoginSuccess({ token: result }));
    } catch (e) {
      if (e instanceof Error) {
        yield* put(actions.fetchFXOpenPALoginFailure(e.message));
      }
    }
  });
}


export function* listOfLpsSaga() {
  yield takeLatest(actions.fetchLpsListStart, function* (action) {
    try {
      const result = yield* call(LpApi.getLPs, action.payload);
      yield* put(actions.fetchLpListSuccess({ lpsList: result }));
    } catch (e) {
      if (e instanceof Error) {
        yield* put(actions.fetchLpListFailure(e.message));
      }
    }
  });
}