import { all } from "redux-saga/effects";
import {
  allNbrbRatesSaga,
} from "./ratesSaga";
import { actions } from "./ratesSlice";
export { ratesReducer } from "./ratesSlice";
export const {
  fetchAllNbrbRatesStart,
  fetchAllNbrbRatesListSuccess,
  fetchAllNbrbRatesListFailure,
  clearAllNbrbRatesListState,
  fetchNbrbRateStart,
  fetchNbrbRateSuccess,
  fetchNbrbRateFailure,
  clearNbrbRateState
} = actions;

export function* restSagas() {
  yield all([
    allNbrbRatesSaga(),
  ]);
}
