import { useEffect, useRef, useState } from "react";
import { AppPages, LinkReportsButtons } from "../../types";
import styles from "./ReportsPage.module.css";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  clearReportState,
  clearReportsListState,
  fetchReportStart,
  fetchReportsListStart,
} from "../../api/reports";
import { checkIsAuth } from "../../helpers";
import { ReportItem } from "../../components/reports/reportItem/ReportItem";
import { TabsMenu } from "../../components/tabs-menu/TabsMenu";
import { Navigate } from "react-router-dom";
import { Header } from "../../components/header/Header";
import { MyDatePicker } from "../../components/date-picker/DatePicker";
import { FormButton } from "../../components/buttons/form-button/FormButton";
import dayjs, { Dayjs } from "dayjs";
import { TableStatement } from "../../components/table/table-statement/TableStatement";
import { FetchStatus } from "../../api/reports/types";
import { Spinner } from "../../components/spinner/Spinner";
import { ArrowBack } from "../../components/test/arrow-back/ArrowBack";

const LINKS_LIST = Object.values(LinkReportsButtons);
type ReportsPageProps = {};

export const ReportsPage: React.FC<ReportsPageProps> = () => {
  const [selectedLink, setSelectedLink] = useState(
    LinkReportsButtons.TREASURY_REPORTS
  );
  const [isAuth, setIsAuth] = useState(true);
  const [reportId, setReportId] = useState("");
  const [page, setPage] = useState("allReports");
  const [dateFrom, setDateFrom] = useState<Dayjs | null>(dayjs(new Date()));
  const [dateTo, setDateTo] = useState<Dayjs | null>(dayjs(new Date()));

  const allReports = useAppSelector((state) => state.reports.reportsList);
  const fetchReportStatus = useAppSelector(
    (state) => state.reports.fetchReportStart
  );
  const report = useAppSelector((state) => state.reports.report);
  const targetRef = useRef(null);
  const [reportSettings, setReportSettings] = useState(
    allReports.find((report) => report.reportId === reportId)
  );

  const dispatch = useAppDispatch();
  let token = localStorage.getItem("accessToken");
  token = token ? JSON.parse(token) : "";

  useEffect(() => {
    dispatch(clearReportState());
    dispatch(clearReportsListState());
    dispatch(fetchReportsListStart({ accessToken: token }));
    checkIsAuth().then((response) => setIsAuth(response));
  }, [dispatch, token]);

  return isAuth ? (
    <>
      <Header selectedMenu="Reports"></Header>
      <TabsMenu
        className={styles.tabs}
        linkEnum={LinkReportsButtons}
        links={LINKS_LIST}
        selectedLink={selectedLink}
        onLinkClick={() => setSelectedLink}
      />
      <section className={styles.main}>
        <div className="container">
          {page === "allReports" ? (
            <ul className={styles.block}>
              {allReports.map((report) => (
                <ReportItem
                  key={report.reportName}
                  report={report}
                  onClick={() => {
                    // dispatch(clearReportsListState());
                    dispatch(clearReportState());
                    setReportId(report.reportId);
                    setPage("report");
                    setReportSettings(
                      allReports.find((item) => item.reportId === report.reportId)
                    );
                  }}
                />
              ))}
            </ul>
          ) : null}
          {page === "report" ? (
            <>
              <ArrowBack
                onClick={() => {
                  dispatch(clearReportState());
                  setReportId("");
                  setPage("allReports");
                  dispatch(fetchReportsListStart({ accessToken: token }));
                }}
              />
              <ReportItem
                className={styles.reportDescBlock}
                key={reportSettings?.reportName}
                report={{
                  reportName: reportSettings?.reportName!,
                  description: reportSettings?.description!,
                }}
              />
              <div className={styles.flex}>
                <MyDatePicker
                  value={dateFrom}
                  defaultValue={
                    reportSettings?.reportParams
                      ? dayjs(
                          reportSettings?.reportParams?.find(
                            (item) => item.paramName === "dateFrom"
                          )?.paramValue
                        )
                      : dayjs(new Date())
                  }
                  onChange={(newValue: Dayjs | null) => setDateFrom(newValue)}
                ></MyDatePicker>
                <MyDatePicker
                  value={dateTo}
                  defaultValue={
                    reportSettings?.reportParams
                      ? dayjs(
                          reportSettings?.reportParams?.find(
                            (item) => item.paramName === "dateTo"
                          )?.paramValue
                        )
                      : dayjs(new Date())
                  }
                  onChange={(newValue: Dayjs | null) => setDateTo(newValue)}
                ></MyDatePicker>
                <FormButton
                  role="button"
                  children="Show"
                  className={styles.button}
                  onClick={() => {
                    dispatch(clearReportState());
                    dispatch(
                      fetchReportStart({
                        reportId: reportId,
                        dateFrom: (dateFrom! > dateTo!) ? dateTo?.format("DDMMYYYY")!:  dateFrom?.format("DDMMYYYY")!,
                        dateTo: dateTo?.format("DDMMYYYY")!,
                        accessToken: token,
                      })
                    );
                  }}
                />
              </div>
              {fetchReportStatus === FetchStatus.PENDING ? (
                <Spinner width={50} height={50} marginTop="10px" />
              ) : (
                <div ref={targetRef}>
                  <TableStatement
                    statementData={report.result}
                    reportId={reportId}
                    dateFrom={dateFrom}
                    dateTo={dateTo}
                    targetRef={targetRef}
                    exports={true}
                  />
                </div>
              )}
            </>
          ) : null}
        </div>
      </section>
    </>
  ) : (
    <Navigate to={AppPages.LOGIN} />
  );
};
