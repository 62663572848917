export enum FetchStatus {
  FAILURE = "failure",
  PENDING = "pending",
  SUCCESS = "success",
}

export type TGetUtilsListPayload = {
  accessToken: string | null;
};

export type TUtilsList = Array<{
  utilId: string;
  utilName: string;
  utilDescr: string;
  func: Function;
}>;

export type TGetUtilStatePayload = {
  accessToken: string | null;
  utilId: string;
};
