import { TGetUserInfoPayload } from "./types";

export namespace UserApi {
  export async function getUserInfo(payload: TGetUserInfoPayload) {
    const server = `/api/user/info`;
    let headers = new Headers({
      Authorization: `Bearer ${payload.accessToken}`,
      "content-type": "application/json",
    });
    try {
      const response = await fetch(server, {
        method: "GET",
        headers: headers
      });

      if (response.status !== 200) {
        const errorText = await response.text();
        throw new Error(errorText);
      }
      const data = await response.json();
      return data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
  export async function postUserLogs(payload: TGetUserInfoPayload) {
    const server = `/api/user/log`;
    let headers = new Headers({
      Authorization: `Bearer ${payload.accessToken}`,
      "content-type": "application/json",
    });
    try {
      const response = await fetch(server, {
        method: "GET",
        headers: headers,
      });

      if (response.status !== 200) {
        const errorText = await response.text();
        throw new Error(errorText);
      }
      const data = await response.json();
      return data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
}
