import { createSlice } from "@reduxjs/toolkit";
import {TResultGetCryptoCurrenciesQuotesArray} from "../../../functions/cryptocurrencies/cryptocurrenciesTypes"
import { FetchStatus, TGetCryptoCurrenciesPayload } from "./types";

const cryptoQuotesSlice = createSlice({
  name: "cryptoQuotes",
  initialState: {
    cryptoQuotes: [],
    fetchCryptoQuotesStatus: null,
  } as {
    cryptoQuotes: TResultGetCryptoCurrenciesQuotesArray;
    fetchCryptoQuotesStatus: FetchStatus | null;
  },
  reducers: {
    fetchCryptoQuotesStart: (
      state,
      action: { payload: TGetCryptoCurrenciesPayload }
    ) => {
      state.fetchCryptoQuotesStatus = FetchStatus.PENDING;
    },
    fetchCryptoQuotesSuccess: (
      state,
      action: { payload: { quotes: any } }
    ) => {
      state.cryptoQuotes = action.payload.quotes;
      state.fetchCryptoQuotesStatus = FetchStatus.SUCCESS;
    },
    fetchCryptoQuotesFailure: (state, action) => {
      state.fetchCryptoQuotesStatus = FetchStatus.FAILURE;
      console.error("Loading Failure", action.payload);
    },
    clearCryptoQuotesState: (state) => {
      state.cryptoQuotes = [];
    },
  },
});

export const { actions } = cryptoQuotesSlice;
export const cryptoQuotesReducer = cryptoQuotesSlice.reducer;
