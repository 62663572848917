import { all } from "redux-saga/effects";
import { bankBalanceSaga, lisOfBanksSaga} from "./bankSaga";
import { actions } from "./bankSlice";
export { bankReducer } from "./bankSlice";
export const {
  fetchBanksBalancesStart,
  fetchBanksBalancesSuccess,
  fetchBanksBalancesFailure,
  clearBanksState,
  fetchBanksListStart,
  fetchBanksListSuccess,
  fetchBanksListFailure,
  clearBanksListState
} = actions;

export function* bankSagas() {
  yield all([bankBalanceSaga(), lisOfBanksSaga()]);
}