import styles from "./DealingPage.module.css";
import { Header } from "../../components/header/Header";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { AppPages, LinkDealingButtons } from "../../types";
import { checkIsAuth } from "../../helpers";
import { TabsMenu } from "../../components/tabs-menu/TabsMenu";
import { PairItem } from "../../components/dealing/pair/PairItem";
import {
  clearPairListState,
  clearPairRateState,
  clearSetPairRateState,
  fetchClearPairRateStart,
  fetchPairListStart,
  fetchPairRateStart,
  fetchSetPairRateStart,
} from "../../api/dealing_rates";
import { RateInput } from "../../components/dealing/rateInput/RateInput";
import { ArrowBack } from "../../components/test/arrow-back/ArrowBack";
import { RateHistoryTable } from "../../components/dealing/rateHistoryTable/RateHistoryTable";
import { RateBlock } from "../../components/dealing/rateBlock/RateBlock";
import { FormButton } from "../../components/buttons/form-button/FormButton";

const LINKS_LIST = Object.values(LinkDealingButtons);
type DealingPageProps = {};

export const DealingPage: React.FC<DealingPageProps> = () => {
  const [selectedLink, setSelectedLink] = useState(LinkDealingButtons.RATES);
  const [isAuth, setIsAuth] = useState(true);
  const [page, setPage] = useState("pairList");
  const [selectedPair, setSelectedPair] = useState<string>("");
  const pairList = useAppSelector((state) => state.dealingRates.pairList);
  const pairRate = useAppSelector((state) => state.dealingRates.pairRate)!;
  const history = useAppSelector((state) => state.dealingRates.history)!;
  let user = localStorage.getItem("login")!;
  let token = localStorage.getItem("accessToken");
  token = token ? JSON.parse(token) : "";
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(clearPairListState());
    dispatch(fetchPairListStart({ accessToken: token }));
    checkIsAuth().then((response) => setIsAuth(response));
  }, [dispatch, token]);

  return isAuth ? (
    <>
      <Header selectedMenu="Dealing"></Header>
      <TabsMenu
        className={styles.tabs}
        linkEnum={LinkDealingButtons}
        links={LINKS_LIST}
        selectedLink={selectedLink}
        onLinkClick={() => setSelectedLink}
      />
      <section className={styles.main}>
        <div className="container">
          {page === "pairList" ? (
            <ul className={styles.pairItemList}>
              {pairList.map((pair) => {
                return (
                  <PairItem
                    pair={pair}
                    onClick={() => {
                      dispatch(clearPairRateState());
                      setSelectedPair(pair);
                      dispatch(
                        fetchPairRateStart({
                          pair: pair,
                          accessToken: token,
                        })
                      );
                      setPage("pairRate");
                    }}
                  />
                );
              })}
            </ul>
          ) : null}
          {page === "pairRate" ? (
            <>
              <ArrowBack
                onClick={() => {
                  dispatch(clearSetPairRateState());
                  setSelectedPair("");
                  setPage("pairList");
                  dispatch(
                    fetchPairListStart({
                      accessToken: token,
                    })
                  );
                }}
              />
              <div className={styles.flex}>
                <div className={styles.flexItem}>
                  <RateInput
                    pair={selectedPair}
                    askRate={pairRate?.ask!}
                    bidRate={pairRate?.bid!}
                    askMarkup={pairRate?.askMarkup}
                    bidMarkup={pairRate?.bidMarkup}
                    handleClick={(
                      newAskRate: string,
                      newBidRate: string,
                      newAskMarkup: string,
                      newBidMarkup: string,
                      pair: string
                    ) => {
                      dispatch(
                        fetchSetPairRateStart({
                          askRate: newAskRate,
                          bidRate: newBidRate,
                          askMarkup: newAskMarkup,
                          bidMarkup: newBidMarkup,
                          accessToken: token,
                          pair: pair,
                          createDate: new Date().getTime(),
                          user: user,
                        })
                      );
                    }}
                  ></RateInput>
                  <div className={styles.flexCurrentRate}>
                    <RateBlock
                      pair={selectedPair}
                      askRate={pairRate?.ask}
                      bidRate={pairRate?.bid}
                      askMarkup={pairRate?.askMarkup}
                      bidMarkup={pairRate?.bidMarkup}
                    />
                    {selectedPair && pairRate ? (
                      <FormButton
                        className={styles.button}
                        onClick={(e) => {
                          dispatch(fetchClearPairRateStart({
                            pair: selectedPair,
                            accessToken: token,
                          }));
                        }}
                      >
                        Clear
                      </FormButton>
                    ) : null}
                  </div>
                </div>
                {history?.length ? (
                  <div className={styles.flexItem}>
                    <p className={styles.name}>Rate History:</p>
                    <RateHistoryTable rateHistory={history} />
                  </div>
                ) : null}
              </div>
            </>
          ) : null}
        </div>
      </section>
    </>
  ) : (
    <Navigate to={AppPages.LOGIN} />
  );
};
