import { TGetAllReportsPayload, TGetSelectedReportPayload } from "./types";

export namespace ReportsApi {
  export async function getAllReports(payload: TGetAllReportsPayload) {
    try {
      const server = `/api/reports/list`;
      let headers = new Headers({
        Authorization: `Bearer ${payload.accessToken}`,
        "content-type": "application/json",
      });
      const response = await fetch(server, {
        method: "GET",
        headers: headers,
      });

      if (response.status !== 200) {
        const errorText = await response.text();
        throw new Error(errorText);
      }

      const data = await response.json();
      return data;
    } catch (e) {
      throw e;
    }
  }

  export async function getSelectedReport(payload: TGetSelectedReportPayload) {
    try {
      const server = `/api/reports/${payload.reportId}?dateFrom=${payload.dateFrom}&dateTo=${payload.dateTo}`;
      let headers = new Headers({
        Authorization: `Bearer ${payload.accessToken}`,
        "content-type": "application/json",
      });
      const response = await fetch(server, {
        method: "GET",
        headers: headers,
      });

      if (response.status !== 200) {
        const errorText = await response.text();
        throw new Error(errorText);
      }

      const data = await response.json();
      return data;
    } catch (e) {
      throw e;
    }
  }
}
