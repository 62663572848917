import styles from "./UtilItem.module.css";
type UtilItemProps = {
  util: {
    utilName: string;
    utilDescr: string;
  };
  onClick: () => void;
};

export const UtilItem: React.FC<UtilItemProps> = ({ util, onClick }) => {
  return (
    <li key={util.utilName} className={styles.utilItem} onClick={onClick}>
      <div>
        <p className={styles.name}>{util.utilName}</p>
        <p className={styles.descr}>{util.utilDescr}</p>
      </div>
    </li>
  );
};
