import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { Dayjs } from "dayjs";
import styles from "./DatePicker.module.css";

type DatePickerProps = {
  value: Dayjs | null
  onChange: (newValue: Dayjs | null) => void;
  defaultValue: Dayjs | null;
};

export const MyDatePicker: React.FC<DatePickerProps> = ({ value, onChange, defaultValue }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["DatePicker"]}>
        <DatePicker
          className={styles.datepicker}
          value={defaultValue || value}
          defaultValue={defaultValue}
          format="DD/MM/YYYY"
          onChange={(newValue) => onChange(newValue as Dayjs | null)}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
};
