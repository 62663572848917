import { all } from "redux-saga/effects";
import { allReportsSaga, selectedReportSaga } from "./reportsSaga";
import { actions } from "./reportsSlice";
export const {
  fetchReportsListStart,
  fetchReportsListSuccess,
  fetchReportsListFailure,
  clearReportsListState,
  fetchReportStart,
  fetchReportSuccess,
  fetchReportFailure,
  clearReportState,
} = actions;

export function* reportSagas() {
  yield all([allReportsSaga(), selectedReportSaga()]);
}
