import { all } from "redux-saga/effects";
import { bankSagas } from "./api/banks";
import { cryptoQuotesSagas } from "./api/cryptoQuotes";
import { lpSagas } from "./api/lps";
import { menuSagas } from "./api/menu";
import { restSagas } from "./api/rates";
import { testSagas } from "./api/test";
import { userSagas } from "./api/user";
import { reportSagas } from "./api/reports";
import { loginSagas } from "./api/auth";
import { dealingRatesSagas } from "./api/dealing_rates";
import { utilsSagas } from "./api/utils";

export function* rootSaga() {
  yield all([
    lpSagas(),
    bankSagas(),
    loginSagas(),
    menuSagas(),
    cryptoQuotesSagas(),
    testSagas(),
    restSagas(),
    userSagas(),
    reportSagas(),
    dealingRatesSagas(),
    utilsSagas()
  ]);
}
