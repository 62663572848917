import { createSlice } from "@reduxjs/toolkit";
import {
  FetchStatus,
  TGetAllReportsPayload,
  TGetSelectedReportPayload,
  TResultOfAllReportsAPI,
  TSelectedReport,
} from "./types";

const reportsSlice = createSlice({
  name: "reports",
  initialState: {
    reportsList: [],
    fetchReportsListStart: null,
    report: [],
    fetchReportStart: null,
  } as {
    reportsList: TResultOfAllReportsAPI;
    fetchReportsListStart: FetchStatus | null;
    report: TSelectedReport;
    fetchReportStart: FetchStatus | null;
  },
  reducers: {
    fetchReportsListStart: (
      state,
      action: { payload: TGetAllReportsPayload }
    ) => {
      state.fetchReportsListStart = FetchStatus.PENDING;
    },

    fetchReportsListSuccess: (
      state,
      action: {
        payload: {
          allReports: TResultOfAllReportsAPI;
        };
      }
    ) => {
      state.reportsList = action.payload.allReports;
      state.fetchReportsListStart = FetchStatus.SUCCESS;
    },
    fetchReportsListFailure: (state, action) => {
      state.fetchReportsListStart = FetchStatus.FAILURE;
      console.error("Loading Failure", action.payload);
    },
    clearReportsListState: (state) => {
      state.fetchReportsListStart = null;
      state.reportsList = [];
    },
    fetchReportStart: (
      state,
      action: { payload: TGetSelectedReportPayload }
    ) => {
      state.fetchReportStart = FetchStatus.PENDING;
    },
    fetchReportSuccess: (
      state,
      action: {
        payload: {
          report: TResultOfAllReportsAPI;
        };
      }
    ) => {
      state.report = action.payload.report;
      state.fetchReportStart = FetchStatus.SUCCESS;
    },
    fetchReportFailure: (state, action) => {
      state.fetchReportStart = FetchStatus.FAILURE;
      console.error("Loading Failure", action.payload);
    },
    clearReportState: (state) => {
      state.fetchReportStart = null;
      state.report = [];
    },
  },
});

export const { actions } = reportsSlice;
export const reportsReducer = reportsSlice.reducer;
