import "./App.css";
import { AppPages } from "./types";
import { Navigate, Route, Routes } from "react-router-dom";
import { LoginPage } from "./pages/login/LoginPage";
import { TwoFactorPage } from "./pages/2fa/TwoFactorPage";
import { MainPage } from "./pages/main/MainPage";
import { LpPage } from "./pages/lp/LpPage";
import { checkIsAuth } from "./helpers";
import { useEffect, useState } from "react";
import { NotFoundPage } from "./pages/not-found/NotFoundPage";
import { TreasuryPage } from "./pages/treasury/TreasuryPage";
import { BanksBelarusPage } from "./pages/banks-belarus/BanksBelarusPage";
import { TestPage } from "./pages/test/TestPage";
import { ReportsPage } from "./pages/reports/ReportsPage";
import { SecretLoginPage } from "./pages/secretlogin/SecretLoginPage";
import { DealingPage } from "./pages/dealing/DealingPage";
import { UtilsPage } from "./pages/utils/UtilsPage";

export function SignIn(isAuth: any) {
  if (isAuth) {
    return <Navigate to="/main" />;
  }
  return <Navigate to="/login" />;
}

function App() {
  const [isAuth, setIsAuth] = useState(true);

  useEffect(() => {
    checkIsAuth().then((response) => setIsAuth(response));
  }, []);

  return (
    <div className="App">
      <Routes>
        <Route path={AppPages.LOGIN} element={<LoginPage />}></Route>
        <Route path={AppPages.TWOFACTOR} element={<TwoFactorPage />}></Route>
        <Route path={AppPages.MAIN} element={<MainPage />}></Route>
        <Route path="/" element={<SignIn isAuth={isAuth}></SignIn>}></Route>
        <Route path={AppPages.TREASURY} element={<BanksBelarusPage />}></Route>
        <Route
          path={AppPages.TREASURY_TOTAL}
          element={<TreasuryPage />}
        ></Route>
        <Route path={AppPages.TREASURY_LPS} element={<LpPage />}></Route>
        <Route
          path={AppPages.BANKS_BELARUS}
          element={<BanksBelarusPage />}
        ></Route>
        <Route path={AppPages.TEST} element={<TestPage />}></Route>
        <Route path={AppPages.WORKERS_TESTING} element={<TestPage />}></Route>
        <Route path={AppPages.UTILS} element={<UtilsPage />}></Route>
        <Route path={AppPages.REPORTS} element={<ReportsPage />}></Route>
        <Route path={AppPages.TREASURY_REPORTS} element={<ReportsPage />}></Route>
        <Route path={AppPages.LOGIN_SECRET} element={<SecretLoginPage />}></Route>
        <Route path={AppPages.DEALING_PAGE} element={<DealingPage />}></Route>
        <Route path={AppPages.DEALING_RATES_PAGE} element={<DealingPage />}></Route>
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </div>
  );
}

export default App;
