import React from "react";
import { LinkButtons, LinkDealingButtons, LinkReportsButtons, LinkSupportButtons } from "../../types";
import { LinkMenu } from "./link/LinkMenu";
import styles from "./TabsMenu.module.css";

type TabsMenuProps = {
  links:LinkButtons[] | LinkSupportButtons[] | LinkReportsButtons[] | LinkDealingButtons[];
  selectedLink:LinkButtons | LinkSupportButtons | LinkReportsButtons | LinkDealingButtons;
  onLinkClick: (selectedLink: LinkButtons | LinkSupportButtons | LinkReportsButtons | LinkDealingButtons) => void;
  className?: string;
  linkEnum: typeof LinkButtons | typeof LinkSupportButtons | typeof LinkReportsButtons | typeof LinkDealingButtons;
};
export const TabsMenu: React.FC<TabsMenuProps> = ({
  links,
  selectedLink,
  onLinkClick,
  linkEnum,
  className
}) => {
  return (
    <div className={styles.sidebar}>
       <div className="container">
      <ul className={className ? `${className} ${styles.ul}` : styles.ul}>
        {links.map((link) => (
          <LinkMenu
            linkEnum={linkEnum}
            onClick={() => onLinkClick(link)}
            selected={selectedLink === link}
            children={link}
            key={link}
          ></LinkMenu>
        ))}
      </ul>
      </div>
    </div>
  );
};