import { FetchStatus } from "../../../api/banks/types";
import styles from "./ExpectedResult.module.css";
type ExpectedResultProps = {
  item:
    | {
        descr: string;
        defaults: {
          url: {
            [key: string]: any;
          };
          body: {
            [k: string]: any;
          };
        };
        result: {
          status: number;
          include: string;
        };
      }
    | undefined;
  resultOfRequest: { status: number; result: any };
  fetchResultOfRequest: FetchStatus | null;
};

export const ExpectedResult: React.FC<ExpectedResultProps> = ({
  item,
  resultOfRequest,
  fetchResultOfRequest,
}) => {
  return item ? (
    <>
      <p className={styles.descr}>Expected Result</p>
      <div className={styles.autotest}>
        <div>
          <p className={styles.p}>Status: {item.result.status}</p>
          <p className={styles.p}>Include: {item.result.include}</p>
        </div>
        {fetchResultOfRequest === FetchStatus.SUCCESS &&
        resultOfRequest.status === item.result.status &&
        JSON.stringify(resultOfRequest.result).includes(item.result.include) ? (
          <p className={styles.statusOK}>OK</p>
        ) : null}
        {fetchResultOfRequest === FetchStatus.SUCCESS &&
        (resultOfRequest.status !== item.result.status ||
          !JSON.stringify(resultOfRequest.result).includes(
            item.result.include
          )) ? (
          <p className={styles.statusFailed}>Failed</p>
        ) : null}
      </div>
    </>
  ) : null;
};
