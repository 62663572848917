import { takeEvery, takeLatest } from "@redux-saga/core/effects";
import { put, call } from "typed-redux-saga";
import { actions } from "./testSlice";
import { TestApi } from "./testApi";

export function* allServicesSaga() {
  yield takeLatest(actions.fetchServiceListStart, function* (action) {
    try {
      const result = yield* call(TestApi.getServices, action.payload);
      yield* put(actions.fetchServiceListSuccess({ serviceList: result }));
    } catch (e) {
      if (e instanceof Error) {
        yield* put(actions.fetchServiceListFailure(e.message));
      }
    }
  });
}

export function* requestsSaga() {
  yield takeEvery(actions.fetchRequestsListStart, function* (action) {
    try {
      const result = yield* call(TestApi.getRequests, action.payload);
      yield* put(actions.fetchRequestsListSuccess({ requests: result }));
    } catch (e) {
      if (e instanceof Error) {
        yield* put(actions.fetchRequestsListFailure(e.message));
      }
    }
  });
}

export function* requestParamsSaga() {
  yield takeEvery(actions.fetchRequestParamsListStart, function* (action) {
    try {
      const result = yield* call(TestApi.getRequestParams, action.payload);
      yield* put(actions.fetchRequestParamsListSuccess({ params: result }));
    } catch (e) {
      if (e instanceof Error) {
        yield* put(actions.fetchRequestParamsListFailure(e.message));
      }
    }
  });
}

export function* resultOfRequestSaga() {
  yield takeEvery(actions.fetchResultOfRequestStart, function* (action) {
    try {
      const result = yield* call(TestApi.getResultOfRequest, action.payload);
      yield* put(actions.fetchResultOfRequestSuccess({ result: result }));
    } catch (e) {
      if (e instanceof Error) {
        yield* put(actions.fetchResultOfRequestFailure(e.message));
      }
    }
  });
}

export function* logsSaga() {
  yield takeLatest(actions.fetchLogsStart, function* (action) {
    try {
      const result = yield* call(TestApi.getLogs, action.payload);
      yield* put(actions.fetchLogsSuccess({ result: result }));
    } catch (e) {
      if (e instanceof Error) {
        yield* put(actions.fetchLogsFailure(e.message));
      }
    }
  });
}
