import { TResultGetCryptoCurrenciesQuotesArray } from "../functions/cryptocurrencies/cryptocurrenciesTypes";
import { TLPItem, TLpRests } from "../functions/lps/lpTypes";
import { checkConnectionApi } from "./api/auth/checkConnection";
import { TBankSummarizedArray } from "./api/banks/types";
import { TQuotesFormatted } from "./api/cryptoQuotes/types";
import { TRatesFormatted, TResultOfNbrbGetRatesAPI } from "./api/rates/types";
import { TOutputBalanceArray } from "./types";

export async function checkIsAuth() {
  let accessToken = localStorage.getItem("accessToken");
  if (!accessToken) {
    return false;
  }
  let response = await checkConnectionApi(JSON.parse(accessToken));
  if (response?.status !== 200) {
    return false;
  }

  return true;
}

export function sortBalancesArrayByLPName(
  namesArray: string[],
  arrayToSort: Array<TLPItem>
) {
  let newArray: Array<TLPItem> = [];

  namesArray.forEach((name: string) => {
    let findedLps = arrayToSort.filter((lp) => name === lp.lpName);
    if (findedLps && findedLps.length) {
      let lpBalances = [];
      for (let lp of findedLps) {
        lpBalances.push(...lp.lpRests);
      }
      newArray.push({
        lpId: findedLps[0].lpId,
        lpName: findedLps[0].lpName,
        lpRests: sortLpRestByCurr(lpBalances),
      });
    } else {
      newArray.push({
        lpId: "errorWithApi",
        lpName: name,
        lpRests: [],
      });
    }
  });

  return newArray;
}

export function sortLpRestByCurr(lpBalances: TLpRests): TLpRests {
  let newArray: Array<{
    symbol: string;
    amount: number[];
    descr?: string;
    frozenAmount?: number[];
    frozenDescr?: string;
  }> = [];

  let totalArray: TLpRests = [];

  lpBalances.forEach((item) => {
    if (item.amount && Number(item.amount) > 0) {
      let findedAmountsElem = newArray.find((i) => item.symbol === i.symbol && item.descr === i.descr)
      if (findedAmountsElem) {
        findedAmountsElem.amount?.push(item.amount);
      } else {
        newArray.push({
          symbol: item.symbol,
          amount: [item.amount],
          descr: item.descr,
          frozenAmount: [],
          frozenDescr: item.frozenDescr,
        });
      }
    }
    if (item.frozenAmount && Number(item.frozenAmount) > 0) {
      let findedFrozenElem = newArray.find((i) => item.symbol === i.symbol && item.frozenDescr === i.frozenDescr)
      

      if (findedFrozenElem) {
        findedFrozenElem.frozenAmount?.push(item.frozenAmount);
      } else {
        newArray.push({
          symbol: item.symbol,
          amount: [],
          descr: item.descr,
          frozenAmount: [item.frozenAmount],
          frozenDescr: item.frozenDescr,
        });
      }
    }
  });

  newArray.forEach((elem) => {
    totalArray.push({
      symbol: elem.symbol,
      amount: elem.amount?.reduce((prev, curr) => {
        return prev + curr;
      }, 0),
      descr: elem.descr,
      frozenAmount: elem.frozenAmount?.reduce((prev, curr) => {
        return prev + curr;
      }, 0) ? elem.frozenAmount?.reduce((prev, curr) => {
        return prev + Number(curr);
      }, 0) : undefined,
      frozenDescr: elem.frozenDescr,
    });
  });

  return totalArray;
}

export function sortBalancesArrayBankName(
  namesArray: string[],
  arrayToSort: TBankSummarizedArray,
  ratesArray: TResultOfNbrbGetRatesAPI
) {
  let newArray: TBankSummarizedArray = [];
  let transformedRates = transformRatesToUSD(ratesArray);
  namesArray.forEach((name: string) => {
    let element = arrayToSort.find((bank) => name === bank.bankName);

    if (element) {
      let totalBankSumInUSD = 0;
      let totalBankCorpSumInUSD = 0;

      element.bankAcounts.forEach((item) => {
        let findedRate = transformedRates[item.symbol];
        if(findedRate) {
          totalBankSumInUSD += (Number(item.amount) * findedRate);
          totalBankCorpSumInUSD += Number(item.corpAmount) * findedRate
        }
      });
      element = Object.assign({}, element, {totalBankSumInUSD:  Math.trunc(totalBankSumInUSD)
        .toString()
        .replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, "$1 "), totalBankCorpSumInUSD: Math.trunc(totalBankCorpSumInUSD)
        .toString()
        .replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, "$1 ")})
      newArray.push(element);
    } else {
      newArray.push({
        bankId: "errorWithApi",
        bankName: name,
        bankAcounts: [],
      });
    }
  });

  return newArray;
}

export function sortBalancesArrayByCurrency(
  sortedCurrencyArray: string[],
  arrayToSort: TOutputBalanceArray,
  ratesArray: TResultOfNbrbGetRatesAPI
) {
  let sortedBankAccount: TOutputBalanceArray = [];
  let ratesFormatted = returnFiatTotalBalanceInUSD(arrayToSort, ratesArray);
  let USDBYNRate = ratesArray.find(
    (item) => item.Cur_Abbreviation === "USD"
  )?.Cur_OfficialRate;
  let totalSumInUSD = 0;
  sortedCurrencyArray.forEach((symbol) => {
    let findedSymbol = ratesFormatted.find((acc) => acc.symbol === symbol);
    if (findedSymbol) {
      sortedBankAccount.push({
        symbol: symbol,
        amount: findedSymbol.amount
          ? Math.trunc(+findedSymbol.amount)
              .toString()
              .replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, "$1 ")
          : 0,
        descr: findedSymbol.descr,
        corpAmount: findedSymbol.corpAmount
        ? Math.trunc(+findedSymbol.corpAmount)
            .toString()
            .replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, "$1 ")
        : 0,
        frozenAmount: findedSymbol.frozenAmount
          ? Math.trunc(+findedSymbol.frozenAmount)
              .toString()
              .replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, "$1 ")
          : 0,
        frozenDescr: findedSymbol.frozenDescr,
        balanceInUSD: Math.trunc(+findedSymbol.balanceInUSD!)
          .toString()
          .replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, "$1 "),
      });
      totalSumInUSD += Number(findedSymbol.balanceInUSD);
    } else {
      sortedBankAccount.push({
        symbol: symbol,
        amount: "-",
        balanceInUSD: "-",
      });
    }
  });
  return {
    sortedBankAccount: sortedBankAccount,
    totalSumInUSD: totalSumInUSD,
    totalSumInBYN: totalSumInUSD * Number(USDBYNRate!)
  };
}

export function sortSymbolsArray(
  arrayToSort: string[],
  settingsArray: string[],
  errorAssets?: string[]
) {
  let newArray: string[] = [];
  let filteredArray = arrayToSort.filter(
    (item) => !settingsArray.includes(item)
  ); //оставляем в массиве валюты, которых нет в настроечном массиве
  //сортируем остаток по алфавиту

  if(errorAssets?.length){
    filteredArray = filteredArray.filter(
      (item) => !errorAssets.includes(item)
    );
  }

  const sortedAlphabeticallyArray = filteredArray.sort((a, b) => {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  });

  newArray = errorAssets?.length ? [...errorAssets, ...settingsArray, ...sortedAlphabeticallyArray] : [...settingsArray, ...sortedAlphabeticallyArray]; //соединяем все в один массив

  return newArray;
}

export function findValueOfCoin(
  symbol: string,
  arrayToSort: TOutputBalanceArray
) {
  let elements = arrayToSort.filter((obj) => symbol === obj.symbol);
  let value: (string | number)[];

  if (elements && elements.length) {
    let elementValuesArray: (string | number)[] = [];
    for (let element of elements) {
      if (element.amount === 0) {
        elementValuesArray.push(element.amount);
      }

      if (element.amount && Number(element.amount) > 0) {
        let value = Math.trunc(+element.amount)
        .toString()
        .replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, "$1 ");
        if(element.descr) {
          value = element.descr + " " + value
        } 

        elementValuesArray.push(value);
      }

      if (element.frozenAmount && Number(element.frozenAmount) > 0) {
        let value = Math.trunc(+element.frozenAmount)
        .toString()
        .replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, "$1 ");
        if(element.frozenDescr) {
          value = element.frozenDescr + " " + value
        } 

        elementValuesArray.push(value);
      }
    }
    value = elementValuesArray;
  } else {
    value = ["-"];
  }

  return value;
}

export function findValueOfCoinWithoutTrunc(
  symbol: string,
  arrayToSort: TOutputBalanceArray
) {
  let elements = arrayToSort.filter((obj) => symbol === obj.symbol);
  let value: (string | number)[];

  if (elements && elements.length) {
    let elementValuesArray: (string | number)[] = [];
    for (let element of elements) {
      if (element.amount === 0) {
        elementValuesArray.push(element.amount);
      }

      if (element.amount && Number(element.amount) > 0) {
        let value = Number(element.amount).toFixed(6);
        if(element.descr) {
          value = element.descr + " " + value
        } 

        elementValuesArray.push(value);
      }

      if (element.frozenAmount && Number(element.frozenAmount) > 0) {
        let value = Number(element.frozenAmount).toFixed(6);
        if(element.frozenDescr) {
          value = element.frozenDescr + " " + value
        } 

        elementValuesArray.push(value);
      }
    }
    value = elementValuesArray;
  } else {
    value = ["-"];
  }
  return value;
}

export function returnSortedTotalBalancesArray(
  namesArray: string[],
  free2exBalancesArray: TOutputBalanceArray,
  totalBanksBalancesArray: TOutputBalanceArray,
  totalLpsBalancesArray: TOutputBalanceArray,
  totalForeignBanksBalancesArray: TOutputBalanceArray
) {
  let newArray: Array<TOutputBalanceArray> = [];

  namesArray.forEach((name: string) => {
    if (name === "Итого, банки РБ") {
      newArray.push(totalBanksBalancesArray);
    }

    if (name === "Итого, банки мира") {
      newArray.push(totalForeignBanksBalancesArray);
    }

    if (name === "Free2ex, блокчейн") {
      newArray.push(free2exBalancesArray);
    }

    if (name === "Итого, LP") {
      newArray.push(totalLpsBalancesArray);
    }
  });

  return newArray;
}

export function returnTotalBalance(
  arrayToCalculate: Array<TOutputBalanceArray>,
  currencyArray: string[]
) {
  let newArray = currencyArray.map((item) => {
    let amountArray: number[] = [];

    arrayToCalculate.forEach((elem) => {
      let findedCurrencies = elem.filter((i) => i.symbol === item);

      if (findedCurrencies) {
        for (let findedCurrency of findedCurrencies) {
          if (findedCurrency?.amount && !isNaN(+findedCurrency?.amount)) {
            amountArray.push(Number(findedCurrency.amount));
          }

          if (findedCurrency?.frozenAmount && !isNaN(+findedCurrency?.frozenAmount)) {
            amountArray.push(Number(findedCurrency.frozenAmount));
          }

          if (findedCurrency?.corpAmount && !isNaN(+findedCurrency?.corpAmount)) {
            amountArray.push(Number(findedCurrency.corpAmount));
          }
        }
      }
    })

      let sumAmountArray: number = Number(
        amountArray.reduce((prev, curr) => {
          return prev + curr;
        }, 0)
      );

      return { symbol: item, amount: sumAmountArray };
    });

  return newArray;
}

function formatQuote(quote: number): string {
  let newQuote = "0";
  if (quote >= 999) {
    newQuote = Math.trunc(quote).toString();
  } else if (quote >= 9) {
    newQuote = quote.toFixed(2);
  } else if (quote >= 1 && quote < 9) {
    newQuote = quote.toFixed(4);
  } else if (quote < 1) {
    newQuote = quote.toFixed(4);
  } else if (quote <= 0.00001) {
    newQuote = "e-16";
  }
  return newQuote;
}

export function returnFormedCCQuotesArray(
  array: TResultGetCryptoCurrenciesQuotesArray
) {
  let formedArray: TQuotesFormatted = [];

  for (let item of array) {
    formedArray.push({
      symbol: item.ccSymbol,
      quote: formatQuote(Number(item.usd_rate)),
      symbolName: item.ccName,
      rank: item.cmc_rank,
      is_active: item.is_active,
      volume_24h: item.volume_24h,
      num_market_pairs: item.num_market_pairs,
      percent_change_24h: item.percent_change_24h,
      percent_change_7d: item.percent_change_7d,
      percent_change_30d: item.percent_change_30d,
      percent_change_90d: item.percent_change_90d,
      market_cap_dominance: item.market_cap_dominance,
    });
  }

  return formedArray;
}

export function returnFormedFiatQuotesArray(
  ratesArray: TResultOfNbrbGetRatesAPI
) {
  let USDObj = ratesArray.find((item) => item.Cur_Abbreviation === "USD");
  let USDRate = USDObj?.Cur_OfficialRate;

  let formedArray = ratesArray.map((item) => {
    let rateToUSD = item.Cur_OfficialRate / USDRate!;
    return {
      symbol: item.Cur_Abbreviation,
      quote: rateToUSD.toFixed(4),
      date: item.Date.split("T")[0],
      quantity: item.Cur_Scale,
    };
  });

  formedArray.push({
    symbol: "BYN",
    quote: (1 / USDRate!).toFixed(4),
    date: USDObj?.Date!,
    quantity: 1,
  });
  return formedArray;
}

export function findQuote(symbol: string, quotesArray: TQuotesFormatted) {
  let value;
  let findedItem;

  if (symbol === "USDT (TRC20)") {
    findedItem = quotesArray.find((item) => item.symbol.toUpperCase() === "USDT");
  } else {
    findedItem = quotesArray.find((item) => item.symbol.toUpperCase() === symbol.toUpperCase());
  }

  if (findedItem) {
    value = findedItem.quote;
  } else {
    value = "";
  }
  return value;
}

export function findRate(symbol: string, quotesArray: TRatesFormatted) {
  let value;
  let findedItem;

  findedItem = quotesArray.find((item) => item.symbol === symbol);

  if (findedItem) {
    value = findedItem.quote;
  } else {
    value = "";
  }
  return value;
}

export function transformRatesToUSD(ratesArray: TResultOfNbrbGetRatesAPI) {
  let USDObj = ratesArray.find((item) => item.Cur_Abbreviation === "USD");
  let USDRate = USDObj?.Cur_OfficialRate;
  let transformedRates: { [key: string]: number } = {};

  ratesArray.forEach((item) => {
    let rateToUSD = item.Cur_OfficialRate / item.Cur_Scale / USDRate!;
    transformedRates[item.Cur_Abbreviation] = rateToUSD;
  });
  transformedRates["BYN"] = 1 / USDRate!;
  transformedRates["XUSD"] = transformedRates["USD"];
  transformedRates["XEUR"] = transformedRates["EUR"];
  return transformedRates;
}

export function returnTotalBalanceInUSD(
  totalBalances: TOutputBalanceArray,
  quotesArray: TResultGetCryptoCurrenciesQuotesArray,
  ratesArray: TResultOfNbrbGetRatesAPI
) {
  let totalBalanceInUSD: TOutputBalanceArray = [];
  let transformedRates = transformRatesToUSD(ratesArray);
  for (let item of totalBalances) {
    let findedValue;
    if (item.symbol === "USDT (TRC20)") {
      findedValue = quotesArray.find((i) => i.ccSymbol.toUpperCase() === "USDT");
    } else {
      findedValue = quotesArray.find((i) => item.symbol.toUpperCase() === i.ccSymbol.toUpperCase());
    }

    let totalSum = 0;
    if (item.amount && !isNaN(Number(item.amount))) {
      totalSum += Number(item.amount);
    }

    if (item.frozenAmount && !isNaN(Number(item.frozenAmount))) {
      totalSum += Number(item.frozenAmount);
    }

    if (item.corpAmount  && !isNaN(Number(item.corpAmount))) {
      totalSum += Number(item.corpAmount);
    }

    if (findedValue) {
      totalBalanceInUSD.push({
        symbol: item.symbol,
        amount: totalSum * findedValue.usd_rate })
      } else {
      totalBalanceInUSD.push({
        symbol: item.symbol,
        amount: totalSum * transformedRates[item.symbol] }
      );
    }
  }

  return totalBalanceInUSD;
}

export function returnFiatTotalBalanceInUSD(
  totalBalances: TOutputBalanceArray,
  ratesArray: TResultOfNbrbGetRatesAPI
) {
  let transformedRates = transformRatesToUSD(ratesArray);
  let newArray = totalBalances.map((curr) => {
    let balanceInUSD = 0;
    if(curr?.amount) {
      balanceInUSD +=Number(curr?.amount) * transformedRates[curr.symbol]
    }
    if(curr?.frozenAmount) {
      balanceInUSD +=Number(curr?.frozenAmount) * transformedRates[curr.symbol]
    }
    if(curr?.corpAmount) {
      balanceInUSD +=Number(curr?.corpAmount) * transformedRates[curr.symbol]
    }
    return {
      ...curr,
      balanceInUSD: balanceInUSD,
    };
  });

  return newArray;
}

export function findCurrencyInfo(
  symbol: string,
  quotesArray: TResultGetCryptoCurrenciesQuotesArray
) {
  let result;
  let findedItem;

  if (symbol === "USDT (TRC20)") {
    findedItem = quotesArray.find((item) => item.ccSymbol.toUpperCase() === "USDT");
  } else {
    findedItem = quotesArray.find((item) => item.ccSymbol.toUpperCase() === symbol.toUpperCase());
  }

  if (findedItem) {
    result = Object.entries({
      Актив: findedItem.ccName,
      Рейтинг: findedItem.cmc_rank,
      Котировка: findedItem.usd_rate,
      "Объем(24ч)": findedItem.volume_24h
        ? findedItem.volume_24h
            .toFixed(0)
            .replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, "$1 ")
        : 0,
      "Изменение(24ч)": findedItem.percent_change_24h?.toFixed(2) + "%",
      "Изменение(7д)": findedItem.percent_change_7d?.toFixed(2) + "%",
      "Изменение(30д)": findedItem.percent_change_30d?.toFixed(2) + "%",
      "Изменение(90д)": findedItem.percent_change_90d?.toFixed(2) + "%",
      "Охват рынка": findedItem.market_cap_dominance + "%",
    });
  }

  return result;
}

export function findFiatCurInfo(symbol: string, quotesArray: TRatesFormatted) {
  let result;
  let findedItem;
  findedItem = quotesArray.find((item) => item.symbol === symbol);

  if (findedItem) {
    result = Object.entries({
      "Дата курса": findedItem.date,
      Размерность: findedItem.quantity,
      "Курс к USD": findedItem.quote,
    });
  }

  return result;
}

export function returnColorOfChanges(item: [string, string | number]) {
  let color = "#181a21";
  if (item[0].startsWith("Изменение")) {
    if (item[1].toString().startsWith("-")) {
      color = "#ff0000";
    } else {
      color = "#02bd02";
    }
  }
  return color;
}

export function returnColor(symbol: string, quotesArray: TQuotesFormatted) {
  let color = "#181a21";
  let findedItem;

  if (symbol === "USDT (TRC20)") {
    findedItem = quotesArray.find((item) => item.symbol === "USDT");
  } else {
    findedItem = quotesArray.find((item) => item.symbol === symbol);
  }

  if (findedItem) {
    if (findedItem["rank"] >= 200) {
      color = "#878700c7";
    }

    if (findedItem.is_active === 0) {
      color = "#ff0000";
    }
  }

  return color;
}

export function returnFindedBankAccounts(
  name: string,
  array: TBankSummarizedArray
) {
  let findedBank = array.find((bank) => bank.bankName === name);
  return findedBank;
}

export function getDefaultUrl(
  url: string,
  defaultValues: { [key: string]: any }
) {
  for (let param in defaultValues) {
    url = url.replace(`:${param}`, defaultValues[param]);
  }
  return url;
}

export function transformBody(
  body: { [key: string]: any },
  defaultBody: { [key: string]: any }
) {
  let filledBody: { [key: string]: any } = {};

  for (let property in body) {
    let value = body[property].replaceAll("$", "");
    filledBody[property] = defaultBody[value];
  }

  return filledBody;
}

export function transformHeaders(
  headers: { [key: string]: any },
  defaultHeaders: { [key: string]: any }
) {
  const objCopy = { ...headers };
  const newDefaultHeaders = {...defaultHeaders}
  for (let head in newDefaultHeaders) {
    let item = sessionStorage.getItem(head)
    if(item) {
      newDefaultHeaders[head] = JSON.parse(item)
    }
  }
  for (let head in newDefaultHeaders) {
    for (let property in objCopy) {
      if (objCopy[property].includes("$" + head + "$")) {
        objCopy[property] = objCopy[property].replace(
          "$" + head + "$",
          newDefaultHeaders[head]
        );
      }
    }
  }
  return objCopy;
}

export function getDateOfUpdate(timestamp: number) {
  const dateArray = new Intl.DateTimeFormat("ru-RU", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    fractionalSecondDigits: 3,
    hour12: false,
    timeZone: "UTC",
  })
    .format(new Date(timestamp))
    .split(", ");

  return `${dateArray[0]}, ${dateArray[1]} UTC`;
}

export function getDateAndTimeOfUpdate(timestamp: number) {
  const dateArray = new Intl.DateTimeFormat("ru-RU", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    fractionalSecondDigits: 3,
    hour12: false,
    timeZone: "UTC",
  })
    .format(new Date(timestamp))
    .split(", ");

  return {
    date: dateArray[0],
    time: `${dateArray[1]} UTC`
  }
}