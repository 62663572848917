import { takeLatest } from "@redux-saga/core/effects";
import { put, call } from "typed-redux-saga";
import { actions } from "./cryptoQuotesSlice";
import { CryptoQuotesApi } from "./cryptoQuotesApi";

export function* cryptoQuotesSaga() {
  yield takeLatest(actions.fetchCryptoQuotesStart, function* (action) {
    try {
      const result = yield* call(CryptoQuotesApi.getCryptoCurrencies, action.payload);
      yield* put(actions.fetchCryptoQuotesSuccess({ quotes: result }));
    } catch (e) {
      if (e instanceof Error) {
        yield* put(actions.fetchCryptoQuotesFailure(e.message));
      }
    }
  });
}
