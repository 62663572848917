import { takeEvery, takeLatest } from "@redux-saga/core/effects";
import { put, call } from "typed-redux-saga";
import { actions } from "./bankSlice";
import { BankApi } from "./bankApi";

export function* bankBalanceSaga() {
  yield takeEvery(actions.fetchBanksBalancesStart, function* (action) {
    try {
      const result = yield* call(BankApi.getBalance, action.payload);
      yield* put(actions.fetchBanksBalancesSuccess({ balances: result }));
    } catch (e) {
      if (e instanceof Error) {
        yield* put(actions.fetchBanksBalancesFailure(e.message));
      }
    }
  });
}

export function* lisOfBanksSaga() {
  yield takeLatest(actions.fetchBanksListStart, function* (action) {
    try {
      const result = yield* call(BankApi.getBanks, action.payload);
      yield* put(actions.fetchBanksListSuccess({ bankList: result }));
    } catch (e) {
      if (e instanceof Error) {
        yield* put(actions.fetchBanksListFailure(e.message));
      }
    }
  });
}