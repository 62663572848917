import styles from "./ArrowBack.module.css";
type ArrowBackProps = {
  onClick: () => void;
};

export const ArrowBack: React.FC<ArrowBackProps> = ({
  onClick
}) => {
  return (
    <div className={styles.arrowBack} onClick={onClick}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M21,11H6.83L10.41,7.41L9,6L3,12L9,18L10.41,16.58L6.83,13H21V11Z" />
    </svg>
  </div>
  );
};
