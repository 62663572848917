import { createSlice } from "@reduxjs/toolkit";
import { FetchStatus, TGetUtilStatePayload, TGetUtilsListPayload, TUtilsList } from "./types";

const utilsSlice = createSlice({
  name: "utils",
  initialState: {
    utilsList: [],
    fetchUtilsListStatus: null,
   utilState: null,
   fetchUtilStateStatus: null
  } as {
    utilsList: TUtilsList,
    fetchUtilsListStatus: FetchStatus | null,
   utilState: null,
   fetchUtilStateStatus: FetchStatus | null
  },
  reducers: {
    fetchUtilsListStart: (
      state,
      action: { payload: TGetUtilsListPayload }
    ) => {
      state.fetchUtilsListStatus = FetchStatus.PENDING;
    },
    fetchUtilsListSuccess: (
      state,
      action: {
        payload: {
          utils: TUtilsList;
        };
      }
    ) => {
      state.utilsList = action.payload.utils;
      state.fetchUtilsListStatus = FetchStatus.SUCCESS;
    },
    fetchUtilsListFailure: (state, action) => {
      state.fetchUtilsListStatus = FetchStatus.FAILURE;
      console.error("Loading Failure", action.payload);
    },
    clearUtilsListState: (state) => {
      state.fetchUtilsListStatus = null;
      state.utilsList = []
    },

    fetchUtilStateStart: (
      state,
      action: { payload: TGetUtilStatePayload }
    ) => {
      state.fetchUtilStateStatus = FetchStatus.PENDING;
    },
    fetchUtilStateSuccess: (
      state,
      action: { payload: any }
    ) => {
      state.utilState = action.payload
      state.fetchUtilStateStatus = FetchStatus.SUCCESS;
    },
    fetchUtilStateFailure: (state, action) => {
      state.fetchUtilStateStatus = FetchStatus.FAILURE;
      console.error("Loading Failure", action.payload);
    },
    clearUtilStateState: (state) => {
      state.utilState = null;
      state.fetchUtilStateStatus = null;
    },
  },
});

export const { actions } = utilsSlice;
export const utilsReducer = utilsSlice.reducer;