import { createSlice } from "@reduxjs/toolkit";
import {
  FetchStatus,
  TGetAllNbrbRatesPayload,
  TGetSelectedNbrbRatesPayload,
  TResultOfNbrbGetRatesAPI,
} from "./types";

const ratesSlice = createSlice({
  name: "rates",
  initialState: {
    nbrbAllRatesList: {
      status: 200,
      result: [],
    },
    fetchAllNbrbRatesListStart: null,
    nbrbRate: {
      status: 200,
      result: [],
    },
    fetchNbrbRatetStart: null,
  } as {
    nbrbAllRatesList: {
      status: number,
      result: TResultOfNbrbGetRatesAPI,
    };
    fetchAllNbrbRatesListStart: FetchStatus | null;
    nbrbRate: {
      status: number,
      result: TResultOfNbrbGetRatesAPI,
    },
    fetchNbrbRatetStart: FetchStatus | null,
  },
  reducers: {
    fetchAllNbrbRatesStart: (
      state, action: { payload: TGetAllNbrbRatesPayload }
    ) => {
      state.fetchAllNbrbRatesListStart = FetchStatus.PENDING;
    },
    fetchAllNbrbRatesListSuccess: (
      state,
      action: {
        payload: {
          nbrbAllRatesList: { status: number; result: TResultOfNbrbGetRatesAPI };
        };
      }
    ) => {
      state.nbrbAllRatesList = action.payload.nbrbAllRatesList;
      state.fetchAllNbrbRatesListStart = FetchStatus.SUCCESS;
    },
    fetchAllNbrbRatesListFailure: (state, action) => {
      state.fetchAllNbrbRatesListStart = FetchStatus.FAILURE;
      console.error("Loading Failure", action.payload);
    },
    clearAllNbrbRatesListState: (state) => {
      state.fetchAllNbrbRatesListStart = null;
      state.nbrbAllRatesList = {
        status: 200,
        result: [],
      };
    },
    fetchNbrbRateStart: (
      state, action: { payload: TGetSelectedNbrbRatesPayload }
    ) => {
      state.fetchNbrbRatetStart = FetchStatus.PENDING;
    },
    fetchNbrbRateSuccess: (
      state,
      action: {
        payload: {
          nbrbRate: { status: number; result: TResultOfNbrbGetRatesAPI };
        };
      }
    ) => {
      state.nbrbRate = action.payload.nbrbRate;
      state.fetchNbrbRatetStart = FetchStatus.SUCCESS;
    },
    fetchNbrbRateFailure: (state, action) => {
      state.fetchNbrbRatetStart = FetchStatus.FAILURE;
      console.error("Loading Failure", action.payload);
    },
    clearNbrbRateState: (state) => {
      state.fetchNbrbRatetStart = null;
      state.nbrbRate = {
        status: 200,
        result: [],
      };
    },
  },
});

export const { actions } = ratesSlice;
export const ratesReducer = ratesSlice.reducer;