export const testPages = {
  "main": {
    order: ["stage", "test", "dev"],
    url: "https://staff-workplace.free2ex.com/support",
  },
  "stage": {
    order: ["main", "test", "dev"],
    url: "https://staff-workplace-stage.free2ex.com/support",
  },
  "test": {
    order: ["dev", "main", "stage"],
    url: "https://staff-workplace-test.free2ex.com/support",
  },
  "dev": {
    order: ["test", "main", "stage"],
    url: "https://staff-workplace-dev.free2ex.com/support",
  },
};
