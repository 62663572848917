export type TLoginPayload = {
  login: string;
  password: string;
  state?: string;
  code?: string;
  identityType?: string;
};
export type TLoginSecret = {
  clientId: string;
  clientSecret: string;
};

export type TLoginResult = {
  accessToken?: string;
  tokenType?: string;
  expiresIn?: number;
  data?: any;
  code?: string;
};

export enum FetchStatus {
  FAILURE = "failure",
  PENDING = "pending",
  SUCCESS = "success",
}
