import { all } from "redux-saga/effects";
import { loginSaga, loginSecretSaga } from "./loginSaga";
import { actions } from "./loginSlice";
export { loginReducer } from "./loginSlice";
export const {
  fetchLoginStart,
  fetchLoginSuccess,
  fetchLoginFailure,
  fetchLoginSecretStart,
  fetchLoginSecretSuccess,
  fetchLoginSecretFailure,
  reset
} = actions;

export function* loginSagas() {
  yield all([loginSaga(), loginSecretSaga()]);
}
