export type TGetCryptoCurrenciesPayload = {
  symbols: string;
  accessToken: string | null;
};

export enum FetchStatus {
  FAILURE = "failure",
  PENDING = "pending",
  SUCCESS = "success",
}

export type TQuotesFormatted = Array<{
  symbol: string;
  quote: number | string;
  symbolName: string;
  rank: number;
  is_active: number;
  volume_24h: number;
  num_market_pairs: number;
  percent_change_24h: number;
  percent_change_7d: number;
  percent_change_30d: number;
  percent_change_90d: number;
  market_cap_dominance: number;
}>
