export type TGetBalancesOfLPPayload = {
  lpId: string;
  subLpId?: string;
  accessToken: string | null;
};

export type TGetListOfLPPayload = {
  accessToken: string | null;
};

export enum FetchStatus {
  FAILURE = "failure",
  PENDING = "pending",
  SUCCESS = "success",
}

export type TFXOpenLoginPayload = {
  accessToken: string | null;
  twoFactorCode?: string;
};