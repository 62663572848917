import styles from "./UtilsPage.module.css";
import { Header } from "../../components/header/Header";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { AppPages, LinkSupportButtons } from "../../types";
import { checkIsAuth } from "../../helpers";
import { TabsMenu } from "../../components/tabs-menu/TabsMenu";
import { ArrowBack } from "../../components/test/arrow-back/ArrowBack";
import { UtilItem } from "../../components/utils/util-item/UtilItem";
import {
  clearUtilStateState,
  clearUtilsListState,
  fetchUtilStateStart,
  fetchUtilsListStart,
} from "../../api/utils";
import { utilsComponents } from "../../api/utils/settings";

const LINKS_LIST = Object.values(LinkSupportButtons);
type UtilsPageProps = {};

export const UtilsPage: React.FC<UtilsPageProps> = () => {
  // let initialREQ_ID = crypto.randomUUID()
  const [selectedLink, setSelectedLink] = useState(LinkSupportButtons.UTILS);
  const [isAuth, setIsAuth] = useState(true);
  const [utilId, setUtilId] = useState("");
  const [page, setPage] = useState("utilsList");
  const utilsList = useAppSelector((state) => state.utils.utilsList);
  const dispatch = useAppDispatch();
  let token = localStorage.getItem("accessToken");
  token = token ? JSON.parse(token) : "";

  useEffect(() => {
    checkIsAuth().then((response) => setIsAuth(response));
    dispatch(fetchUtilsListStart({ accessToken: token }));
  }, [dispatch, token]);

  return isAuth ? (
    <>
      <Header selectedMenu="IT Support"></Header>
      <TabsMenu
        className={styles.tabs}
        linkEnum={LinkSupportButtons}
        links={LINKS_LIST}
        selectedLink={selectedLink}
        onLinkClick={() => setSelectedLink}
      />
      <section className={styles.main}>
        <div className="container">
          {page === "utilsList" ? (
            <div className={styles.flex}>
              <div className={styles.block}>
                {utilsList.map((util) => (
                  <UtilItem
                    key={util.utilId}
                    util={util}
                    onClick={() => {
                      dispatch(clearUtilsListState());
                      setUtilId(util.utilId);
                      setPage("util");
                    }}
                  />
                ))}
              </div>
            </div>
          ) : null}

          {page === "util" ? (
            <div>
              <ArrowBack
                onClick={() => {
                  dispatch(clearUtilStateState());
                  dispatch(
                    fetchUtilsListStart({
                      accessToken: token,
                    })
                  );
                }}
              />
              <>
                {utilsComponents[utilId as keyof typeof utilsComponents]
                  ? utilsComponents[utilId as keyof typeof utilsComponents]({
                      onClick: () => {
                        dispatch(
                          fetchUtilStateStart({
                            accessToken: token,
                            utilId: utilId,
                          })
                        );
                      },
                    })
                  : null}
              </>
            </div>
          ) : null}
        </div>
      </section>
    </>
  ) : (
    <Navigate to={AppPages.LOGIN} />
  );
};
