import styles from "./ReportItem.module.css";
type ReportItemProps = {
  report: {
    reportName: string;
    description: string;
  };
  onClick?: () => void;
  className?:string
};

export const ReportItem: React.FC<ReportItemProps> = ({ report, onClick, className }) => {
  return (
    <li key={report.reportName} className={className ? `${styles.reportItem} ${className}` : styles.reportItem} onClick={onClick}>
        <p className={styles.name}>{report.reportName}</p>
        <p className={styles.descr}>{report.description}</p>
    </li>
  );
};
