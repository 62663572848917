import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import createSagaMiddleware from "redux-saga";
import { bankReducer } from "./api/banks";
import { lpsReducer } from "./api/lps";
import { loginReducer } from "./api/auth";
import { rootSaga } from "./sagas";
import { menuReducer } from "./api/menu";
import { cryptoQuotesReducer } from "./api/cryptoQuotes";
import { testReducer } from "./api/test";
import { ratesReducer } from "./api/rates";
import { userReducer } from "./api/user";
import { reportsReducer } from "./api/reports/reportsSlice";
import { dealingRatesReducer } from "./api/dealing_rates";
import { utilsReducer } from "./api/utils";

let sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];
export const allReducers = combineReducers({
  lps: lpsReducer,
  banks: bankReducer,
  login: loginReducer,
  menu: menuReducer,
  cryptoQuotes: cryptoQuotesReducer,
  test: testReducer,
  rates:ratesReducer,
  user: userReducer,
  reports: reportsReducer,
  dealingRates: dealingRatesReducer,
  utils: utilsReducer
});
export const store = configureStore({
  reducer: allReducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(middleware),
});

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
