export const bankCurrencySettingsArray = ["USD", "EUR", "RUB", "BYN", "CNY", "CHF"];
export const lpCurrencySettingsArray = [
  "USD",
  "EUR",
  "USDT",
  "USDT (TRC20)",
  "USDC",
  "DAI",
  "EURt",
  "CNHt",
  "ETH",
  "TRX",
  "BTC",
  "LTC",
  "BCH",
];
export const totalTableСolumns = [
  "Итого, банки РБ",
  "Итого, банки мира",
  "Free2ex, блокчейн",
  "Итого, LP",
];

export const totalTableCurrencySettingsArray = [
  "USD",
  "EUR",
  "RUB",
  "BYN",
  "CNY",
  "CHF",
  "USDT",
  "USDT (TRC20)",
  "USDC",
  "DAI",
  "EURt",
  "CNHt",
  "ETH",
  "TRX",
  "BTC",
  "LTC",
  "BCH",
];

export const fiatCurrencyArrayToFilter = [
  "USD",
  "EUR",
  "RUB",
  "BYN",
  "CNY",
  "CHF"
];

export const stablecoinsArrayToFilter = ["USDT", "USDC", "DAI", "USDT (TRC20)"];

export const cryptovArrayToFilter = ["USDT", "USDT (TRC20)", "USDC", "DAI", "BTC", "LTC", "BCH", "ETH", "TRX", "EURt", "CNHt"];

export function returnFilterCurrencyArray(selectedFilter: string) {
  if (selectedFilter === "fiat") {
    return fiatCurrencyArrayToFilter;
  }
  if (selectedFilter === "stablecoins") {
    return stablecoinsArrayToFilter;
  }
  if (selectedFilter === "cryptov") {
    return cryptovArrayToFilter;
  }
  return [];
}