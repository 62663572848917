import { Oval } from "react-loader-spinner";

type SpinnerProps = {
  width:number;
  height:number;
  marginTop: string
};

export const Spinner: React.FC<SpinnerProps> = ({width, height, marginTop} ) => {
  return (
    <Oval
      height={height}
      width={width}
      color="#264d45"
      wrapperStyle={{
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        marginTop: marginTop,
      }}
      wrapperClass=""
      visible={true}
      ariaLabel="oval-loading"
      secondaryColor="#00BEB4"
      strokeWidth={2}
      strokeWidthSecondary={2}
    />
  );
};
