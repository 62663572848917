import { all } from "redux-saga/effects";
import {
  utilStateSaga,
  utilsListSaga,
} from "./utilsSaga";
import { actions } from "./utilsSlice";
export { utilsReducer } from "./utilsSlice";
export const {
  fetchUtilsListStart,
  fetchUtilsListSuccess,
  fetchUtilsListFailure,
  clearUtilsListState,
  fetchUtilStateStart,
  fetchUtilStateSuccess,
  fetchUtilStateFailure,
  clearUtilStateState
} = actions;

export function* utilsSagas() {
  yield all([
    utilsListSaga(),
    utilStateSaga(),
  ]);
}
