import { createSlice } from "@reduxjs/toolkit";
import { FetchStatus, TLoginPayload, TLoginResult, TLoginSecret } from "./types";

const loginSlice = createSlice({
  name: "login",
  initialState: {
    result: null,
    fetchStatus: null,
  } as {
    result: TLoginResult | null;
    fetchStatus: FetchStatus | null;
  },
  reducers: {
    fetchLoginStart: (state, action: { payload: TLoginPayload }) => {
      state.fetchStatus = FetchStatus.PENDING;
    },
    fetchLoginSuccess: (state, action: { payload: { login: any } }) => {
      state.result = action.payload.login;
      state.fetchStatus = FetchStatus.SUCCESS;
    },
    fetchLoginFailure: (state, action) => {
      state.fetchStatus = FetchStatus.FAILURE;
      console.error("Loading Failure", action.payload);
    },
    fetchLoginSecretStart: (state, action: { payload: TLoginSecret }) => {
      state.fetchStatus = FetchStatus.PENDING;
    },
    fetchLoginSecretSuccess: (state, action: { payload: { login: any } }) => {
      state.result = action.payload.login;
      state.fetchStatus = FetchStatus.SUCCESS;
    },
    fetchLoginSecretFailure: (state, action) => {
      state.fetchStatus = FetchStatus.FAILURE;
      console.error("Loading Failure", action.payload);
    },
    reset(state) {
      state.fetchStatus = null;
    },
  },
});

export const { actions } = loginSlice;
export const loginReducer = loginSlice.reducer;
