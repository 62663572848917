import { TServiceRequestsList } from "../../../api/test/types";
import styles from "./SettingsBlock.module.css";
type SettingsBlockProps = {
  serviceRequests: {
    status: number;
    result: TServiceRequestsList;
  };
  paramsArray?: JSX.Element[];
};

export const SettingsBlock: React.FC<SettingsBlockProps> = ({
  serviceRequests,
  paramsArray,
}) => {
  return (
    <div>
      <div className={styles.varsBlock}>
        {(() => {
          let arrayOfParams = [];
          for(let item in serviceRequests.result.vars){
            arrayOfParams.push(<p className={styles.var}>{item}: {serviceRequests.result.vars[item]}</p>)
          }
          return arrayOfParams
        })()
        }
      </div>
     {paramsArray?.length ? <div>{paramsArray}</div> : null}
    </div>
  );
};
