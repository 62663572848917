import {
  TGetListOfSetvicesPayload,
  TGetLogsPayload,
  TGetRequestParamsPayload,
  TGetRequestsPayload,
  TGetResultOfRequestPayload,
} from "./types";

export namespace TestApi {
  export async function getServices(payload: TGetListOfSetvicesPayload) {
    try {
      const server = `/api/test/all_services`;
      let headers = new Headers({
        Authorization: `Bearer ${payload.accessToken}`,
        "content-type": "application/json",
      });
      const response = await fetch(server, {
        method: "GET",
        headers: headers,
      });

      if (response.status !== 200) {
        const errorText = await response.text();
        throw new Error(errorText);
      }

      const data = await response.json();
      return data;
    } catch (e) {
      throw e;
    }
  }

  export async function getRequests(payload: TGetRequestsPayload) {
    try {
      const server = `/api/test/requests/${payload.serviceName}`;
      let headers = new Headers({
        Authorization: `Bearer ${payload.accessToken}`,
        "content-type": "application/json",
      });
      const response = await fetch(server, {
        method: "GET",
        headers: headers,
      });

      if (response.status !== 200) {
        const errorText = await response.text();
        throw new Error(errorText);
      }

      const data = await response.json();
      return data;
    } catch (e) {
      throw e;
    }
  }

  export async function getRequestParams(payload: TGetRequestParamsPayload) {
    try {
      const server = `/api/test/requests/${payload.serviceName}/${payload.requestId}`;
      let headers = new Headers({
        Authorization: `Bearer ${payload.accessToken}`,
        "content-type": "application/json",
      });
      const response = await fetch(server, {
        method: "GET",
        headers: headers,
      });

      if (response.status !== 200) {
        const errorText = await response.text();
        throw new Error(errorText);
      }
      const data = await response.json();
      return data;
    } catch (e) {
      throw e;
    }
  }

  export async function getResultOfRequest(
    payload: TGetResultOfRequestPayload
  ) {
    try {
      const server = `/api/test/requests/${payload.serviceName}/${payload.requestId}`;
      let headers = new Headers({
        Authorization: `Bearer ${payload.accessToken}`,
        "content-type": "application/json",
      });
      if (payload.reqHeaders) {
        headers = Object.assign(headers, payload.reqHeaders);
      }
      const response = await fetch(server, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          url: payload.url,
          method: payload.method,
          body: payload.reqBody,
          headers: payload.reqHeaders,
        }),
      });
      const data = await response.json();
      return {
        [payload.index]: data,
      };
    } catch (e) {
      throw e;
    }
  }

  export async function getLogs(
    payload: TGetLogsPayload
  ) {
    try {
      const server = `/api/test/log/${payload.id}`;
      let headers = new Headers({
        Authorization: `Bearer ${payload.accessToken}`,
        "content-type": "application/json",
      });
      
      const response = await fetch(server, {
        method: "GET",
        headers: headers,
      });

      if (response.status !== 200) {
        const errorText = await response.text();
        throw new Error(errorText);
      }
      const data = await response.json();
      return data
    } catch (e) {
      throw e;
    }
  }
}
