import { TOutputBalanceArray } from "../../types";
import { TBankArray, TBankSummarizedArray } from "./types";

export function sumBankBalanceByCurrency(
  arrayToSort: TBankArray,
  currencyArray: string[]
) {
  let newArray: TBankSummarizedArray = [];

  newArray = arrayToSort.map((bank) => {
    let newBankAccountsArray: TOutputBalanceArray = [];
    currencyArray.forEach((item) => {
      let totalSum = 0;
      let corpSum = 0;
      let filteredTotalArray = bank.bankAcounts.filter((i) => item === i.symbol && i.type !== "corp" );
      let filteredCorpArray = bank.bankAcounts.filter((i) => item === i.symbol && i.type === "corp");
      if(filteredTotalArray.length) {
        totalSum = Math.ceil(filteredTotalArray.reduce((prev, curr) => {
          return prev + curr.amount;
        }, 0))
      }
      if(filteredCorpArray.length) {
        corpSum = Math.ceil(filteredCorpArray.reduce((prev, curr) => {
          return prev + curr.amount;
        }, 0))
      }
        newBankAccountsArray.push({ symbol: item, amount: totalSum, corpAmount: corpSum}); 
    });
    return {
      bankId: bank.bankId,
      bankName: bank.bankName,
      bankAcounts: newBankAccountsArray,
    };
  });
  return newArray;
}

export function returnTotalBankBalance(
  arrayToCalculate: TBankSummarizedArray,
  currencyArray: string[]
) {
  let newArray: TOutputBalanceArray = [];

  newArray = currencyArray.map((item) => {
    let totalAmountArray: number[] = [];
    let corpAmountArray: number[] = [];
    arrayToCalculate.forEach((bank) => {
      let findedCurrency = bank.bankAcounts.find((i) => item === i.symbol);

      if (findedCurrency) {
        if(findedCurrency.corpAmount ){
          corpAmountArray.push(+findedCurrency.corpAmount);
        } 
          totalAmountArray.push(+findedCurrency.amount);
      }
    });

    let totalSum = totalAmountArray.reduce((prev, curr) => {
      return prev + curr;
    }, 0);
    let corpSum = corpAmountArray.reduce((prev, curr) => {
      return prev + curr;
    }, 0);

    return { symbol: item, amount: totalSum, corpAmount: corpSum }
  });

  return newArray;
}
