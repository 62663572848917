import styles from "./RateHistoryTable.module.css";
type RateHistoryTableProps = {
  rateHistory: Array<any>;
};


let tableHeaders = ["ask", "askMarkup", "bid", "bidMarkup", "createDate"];

export const RateHistoryTable: React.FC<RateHistoryTableProps> = ({
  rateHistory,
}) => {
  let formattedHistory:any = [];

    rateHistory.forEach((item:any) => {
      let arr =[];
      for( let header of tableHeaders) {
        if(header === "createDate") {
          arr.push(new Date(+item[header])?.toISOString())
        } else {
          arr.push(item[header] ? item[header] : "-")
        }
      }
      formattedHistory.push(arr)
    })

  return (
    <table className={styles.table} id="table">
      <tr>
        {tableHeaders.map((header) => {
          return <th className={styles.th}>{header}</th>;
        })}
      </tr>

      {formattedHistory.map((item:[]) => {
        return (
          <tr>
            {item.map((elem: any) => {
              return <td className={styles.td}>{elem}</td>
            })}
          </tr>
        );
      })}
    </table>
  );
};
