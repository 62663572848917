import { all } from "redux-saga/effects";
import {
  allServicesSaga,
  logsSaga,
  requestParamsSaga,
  requestsSaga,
  resultOfRequestSaga,
} from "./testSaga";
import { actions } from "./testSlice";
export { testReducer } from "./testSlice";
export const {
  fetchRequestsListStart,
  fetchRequestsListSuccess,
  fetchRequestsListFailure,
  clearRequestsListState,
  fetchServiceListStart,
  fetchServiceListSuccess,
  fetchServiceListFailure,
  clearServiceListState,
  fetchRequestParamsListStart,
  fetchRequestParamsListSuccess,
  fetchRequestParamsListFailure,
  clearRequestParamsListState,
  fetchResultOfRequestStart,
  fetchResultOfRequestSuccess,
  fetchResultOfRequestFailure,
  clearResultOfRequestState,
  fetchLogsStart,
  fetchLogsFailure,
  fetchLogsSuccess,
  clearLogsState
} = actions;

export function* testSagas() {
  yield all([
    allServicesSaga(),
    requestsSaga(),
    requestParamsSaga(),
    resultOfRequestSaga(),
    logsSaga()
  ]);
}
