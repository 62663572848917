import { commonPostHeaders } from "../config";
import { TLoginPayload, TLoginSecret } from "./types";

export namespace loginApi {
  export async function login(payload: TLoginPayload) {
    const server = `/api/authorization/login`;
    try {
      const response = await fetch(server, {
        method: "POST",
        body: JSON.stringify({
          login: payload.login,
          password: payload.password,
          state: payload.state,
          code: payload.code,
          identityType: payload.identityType,
        }),
        headers: commonPostHeaders,
      });
      const data = await response.json();
      if (!sessionStorage.getItem("user")) {
        sessionStorage.setItem(
          "user",
          JSON.stringify({
            login: payload.login,
            pass: payload.password,
            identityTypes: data.data.identityTypes,
            state: data.data.state,
          })
        );
        localStorage.setItem("login", payload.login);
      }
      return data;
    } catch (error) {
      console.log(error);
    }
  }
  export async function loginBySecret(payload: TLoginSecret) {
    const server = `/api/authorization/secretLogin`;
    try {
      const response = await fetch(server, {
        method: "POST",
        body: JSON.stringify({
          clientId: payload.clientId,
          clientSecret: payload.clientSecret,
        }),
        headers: commonPostHeaders,
      });
      const data = await response.json();
      localStorage.setItem("login", "auto-bot@free2ex.com");
      localStorage.setItem("accessToken", JSON.stringify(data?.accessToken));
      return data;
    } catch (error) {
      console.log(error);
    }
  }
}
