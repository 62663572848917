import { createSlice } from "@reduxjs/toolkit";
import { TLogUserActivity, TResultOfGetUserInfo } from "../../../functions/types";
import { FetchStatus, TGetUserInfoPayload } from "./types";

const userSlice = createSlice({
  name: "user",
  initialState: {
    info: null,
    fetchUserInfo: null
  } as {
    info: TResultOfGetUserInfo | null;
    fetchUserInfo: FetchStatus | null
  },
  reducers: {
    fetchUserInfoStart: (
      state,
      action: { payload: TGetUserInfoPayload }
    ) => {
      state.fetchUserInfo = FetchStatus.PENDING;
    },
    fetchUserInfoSuccess: (
      state,
      action: { payload: { info: TResultOfGetUserInfo } }
    ) => {
      state.info = action.payload.info;
      state.fetchUserInfo = FetchStatus.SUCCESS;
    },
    fetchUserInfoFailure: (state, action) => {
      state.fetchUserInfo = FetchStatus.FAILURE;
      console.error("Loading Failure", action.payload);
    },
    clearUserInfoState: (state) => {
      state.info = null;
    },
    fetchPostLogsStart: (state, action: { payload: TGetUserInfoPayload }) => {
    },
    fetchPostLogsSuccess: (state, action: { payload: TLogUserActivity }) => {
    },
    fetchPostLogsFailure: (state, action) => {
      console.error("Loading Failure", action.payload);
    },
  },
});

export const { actions } = userSlice;
export const userReducer = userSlice.reducer;
