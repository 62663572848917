import { takeLatest } from "@redux-saga/core/effects";
import { put, call } from "typed-redux-saga";
import { UserApi } from "./userApi";
import { actions } from "./userSlice";

export function* userInfoSaga() {
  yield takeLatest(actions.fetchUserInfoStart, function* (action) {
    try {
      const result = yield* call(UserApi.getUserInfo, action.payload);
      yield* put(actions.fetchUserInfoSuccess({ info: result }));
    } catch (e) {
      if (e instanceof Error) {
        yield* put(actions.fetchUserInfoFailure(e.message));
      }
    }
  });
}

export function* userLogSaga() {
  yield takeLatest(actions.fetchPostLogsStart, function* (action) {
    try {
      const result = yield* call(UserApi.postUserLogs, action.payload);
      yield* put(actions.fetchPostLogsSuccess(result));
    } catch (e) {
      if (e instanceof Error) {
        yield* put(actions.fetchPostLogsFailure(e.message));
      }
    }
  });
}
