export enum FetchStatus {
  FAILURE = "failure",
  PENDING = "pending",
  SUCCESS = "success",
}

export type TGetAllReportsPayload = {
  accessToken: string | null;
};

export type TGetSelectedReportPayload = {
  accessToken: string | null
  reportId:string;
  dateFrom: string;
  dateTo: string
};

export type TResultOfAllReportsAPI = Array<{
  reportId: string;
  reportName: string;
  description: string;
  func: () => any;
  scope: string[];
  reportParams: Array<{
    paramType: string,
    paramName: string,
    paramValue: Date,
  }>
}>;

export type TSelectedReport = any;