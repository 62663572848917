import { TOutputBalanceArray } from "../../types";

export type TGetBalancesOfBankPayload = {
  bankId: string;
  accessToken: string | null;
};

export type TBankArray = Array<{
  bankId: string;
  bankName: string;
  bankAcounts: Array<{
    account: string;
    symbol: string;
    amount: number;
    type: string;
  }>;
}>;

export type TBankSummarizedArray = Array<{
  bankId: string;
  bankName: string;
  bankAcounts: TOutputBalanceArray;
  totalBankSumInUSD?:number;
  totalBankCorpSumInUSD?: number;
}>;

export enum FetchStatus {
  FAILURE = "failure",
  PENDING = "pending",
  SUCCESS = "success",
}

export type TGetListOfBanksPayload = {
  accessToken: string | null;
};