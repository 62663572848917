import { TGetUtilStatePayload, TGetUtilsListPayload } from "./types";

export namespace UtilsApi {
  export async function getUtils(payload: TGetUtilsListPayload) {
    try {
      const server = `/api/utils/list`;
      let headers = new Headers({
        Authorization: `Bearer ${payload.accessToken}`,
        "content-type": "application/json",
      });
      const response = await fetch(server, {
        method: "GET",
        headers: headers,
      });

      if (response.status !== 200) {
        const errorText = await response.text();
        throw new Error(errorText);
      }

      const data = await response.json();
      return data;
    } catch (e) {
      throw e;
    }
  }

  export async function getUtilState(payload: TGetUtilStatePayload) {
    try {
      const server = `/api/utils/${payload.utilId}`;
      let headers = new Headers({
        Authorization: `Bearer ${payload.accessToken}`,
        "content-type": "application/json",
      });
      const response = await fetch(server, {
        method: "GET",
        headers: headers,
      });

      if (response.status !== 200) {
        const errorText = await response.text();
        throw new Error(errorText);
      }

      const data = await response.text();
      return data;
    } catch (e) {
      throw e;
    }
  }
}
