import { createSlice } from "@reduxjs/toolkit";
import { returnTotalLpBalance } from "./lpHelpers";
import { FetchStatus, TFXOpenLoginPayload, TGetBalancesOfLPPayload, TGetListOfLPPayload } from "./types";
import {TLPItem} from "../../../functions/lps/lpTypes";

const lpsSlice = createSlice({
  name: "lps",
  initialState: {
    lpsList: [],
    lpsBalances: [],
    totalValuesArray : [],
    fxopenToken: "",
    fetchLpsListStatus:null,
    fetchBalanceStatus: null,
    fetchLpsPABalancesStatus:null,
    fetchFXOpenPALoginStatus:null,
    // isLocked: false,
  } as {
    lpsList:  Array<{
      lpName: string;
      lpId: string;
      subLpIds?: string[]
    }>;
    totalValuesArray: Array<{symbol: string, amount: number}>;
    fxopenToken: string,
    lpsBalances: Array<TLPItem>;
    fetchBalanceStatus: FetchStatus | null;
    fetchLpsListStatus: FetchStatus | null;
    fetchLpsPABalancesStatus: FetchStatus | null;
    fetchFXOpenPALoginStatus: FetchStatus | null;
    // isLocked: boolean;
  },
  reducers: {
    fetchlpsBalancesStart: (
      state,
      action: { payload: TGetBalancesOfLPPayload }
    ) => {
      state.fetchBalanceStatus = FetchStatus.PENDING;
    },
    fetchlpsBalancesSuccess: (
      state,
      action: { payload: { balances: any } }
    ) => {
      state.lpsBalances.push(action.payload.balances)
      let currencyArray:string[] = [];
      state.lpsBalances.forEach((lp) => {
        lp.lpRests.map(item => currencyArray.push(item.symbol))
      });
      let uniqueCurrencyArray = [...new Set(currencyArray)];
      state.totalValuesArray = returnTotalLpBalance(state.lpsBalances, uniqueCurrencyArray) //получение массива для столбца "Итого"
      state.fetchBalanceStatus = FetchStatus.SUCCESS;
    },
    fetchlpsBalancesFailure: (state, action) => {
      state.fetchBalanceStatus = FetchStatus.FAILURE;
      console.error("Loading Failure", action.payload);
    },
    clearlpsState: (state) => {
      state.lpsBalances = [];
      state.totalValuesArray = [];
    },
    fetchLpsListStart: (
      state,
      action: { payload: TGetListOfLPPayload }
    ) => {
      state.fetchLpsListStatus = FetchStatus.PENDING;
    },
    fetchLpListSuccess: (
      state,
      action: { payload: { lpsList: any } }
    ) => {
      state.lpsList = action.payload.lpsList;
      state.fetchLpsListStatus = FetchStatus.SUCCESS;
    },
    fetchLpListFailure: (state, action) => {
      state.fetchLpsListStatus = FetchStatus.FAILURE;
      console.error("Loading Failure", action.payload);
    },
    clearLpListState: (state) => {
      state.lpsList = [];
    },
    fetchlpsPABalancesStart: (
      state,
      action: { payload: TGetBalancesOfLPPayload }
    ) => {
      state.fetchLpsPABalancesStatus = FetchStatus.PENDING;
    },
    fetchlpsPABalancesSuccess: (
      state,
      action: { payload: { balances: any } }
    ) => {
      // if(action.payload.balances?.result === "locked") {
      //   state.isLocked = true;
      // } else {
        let findedLp = state.lpsBalances.find((lp) => lp.lpId === "fxopen_id")

        if(findedLp) {
          state.lpsBalances = state.lpsBalances.map((lp) => {
            if(lp === findedLp) {
              lp.lpRests.push(...action.payload.balances.lpRests)
              return {
                lpId:lp.lpId,
                lpName: lp.lpName,
                subLpId: lp.subLpId,
                lpRests: lp.lpRests
              }
            } else {
              return lp;
            }
          }
          )
        } else {
          state.lpsBalances.push(action.payload.balances);
        }
        let currencyArray:string[] = [];
        state.lpsBalances.forEach((lp) => {
          lp.lpRests.map(item => currencyArray.push(item.symbol))
        });
        let uniqueCurrencyArray = [...new Set(currencyArray)];
        state.totalValuesArray = returnTotalLpBalance(state.lpsBalances, uniqueCurrencyArray) //получение массива для столбца "Итого"
        state.fetchLpsPABalancesStatus = FetchStatus.SUCCESS;
      //   state.isLocked = false;
      // }
    },
    fetchlpsPABalancesFailure: (state, action) => {
      state.fetchLpsPABalancesStatus = FetchStatus.FAILURE;
      console.error("Loading Failure", action.payload);
    },
    fetchFXOpenPALoginStart: (
      state,
      action: { payload: TFXOpenLoginPayload }
    ) => {
      state.fetchFXOpenPALoginStatus = FetchStatus.PENDING;
    },
    fetchFXOpenPALoginSuccess: (
      state,
      action: { payload: { token: string } }
    ) => {
      state.fxopenToken = action.payload.token;
      state.fetchFXOpenPALoginStatus = FetchStatus.SUCCESS;
    },
    fetchFXOpenPALoginFailure: (state, action) => {
      state.fetchFXOpenPALoginStatus = FetchStatus.FAILURE;
      console.error("Login Failure", action.payload);
    },
  },
});

export const { actions } = lpsSlice;
export const lpsReducer = lpsSlice.reducer;
