import styles from "./RateBlock.module.css";
type RateBlockProps = {
  pair: string | undefined;
  askRate: string | null;
  bidRate: string | null;
  askMarkup: string | null;
  bidMarkup: string | null;
};

export const RateBlock: React.FC<RateBlockProps> = ({
  pair,
  askRate,
  bidRate,
  askMarkup,
  bidMarkup,
}) => {
  return (
    <div>
      {pair && askRate ? (
        <p className={styles.name}>
          ASK {pair} rate - {askRate}
        </p>
      ) : null}
      {pair && bidRate ? (
        <p className={styles.name}>
          BID {pair} rate - {bidRate}
        </p>
      ) : null}
      {pair && askMarkup ? (
        <p className={styles.name}>
          ASK {pair} markup - {askMarkup}%
        </p>
      ) : null}
      {pair && bidMarkup ? (
        <p className={styles.name}>
          BID {pair} markup - {bidMarkup}%
        </p>
      ) : null}
    </div>
  );
};
