import { Link } from "react-router-dom";
import { AppPages } from "../../types";
import styles from "./NotFoundPage.module.css";
type NotFoudPageProps = {};

export const NotFoundPage: React.FC<NotFoudPageProps> = () => {
  return (
    <div className={styles.wrapper}>
       <div className="container">
      <h1 className={styles.code}>404</h1>
      <h2 className={styles.text}>PAGE NOT FOUND</h2>
      <Link className={styles.button} to={AppPages.MAIN}>
        На главную
      </Link>
    </div>
    </div>
  );
};
