import { all } from "redux-saga/effects";
import { cryptoQuotesSaga} from "./cryptoQuotesSaga";
import { actions } from "./cryptoQuotesSlice";
export { cryptoQuotesReducer } from "./cryptoQuotesSlice";
export const {
  fetchCryptoQuotesStart,
  fetchCryptoQuotesSuccess,
  fetchCryptoQuotesFailure,
  clearCryptoQuotesState,
} = actions;

export function* cryptoQuotesSagas() {
  yield all([cryptoQuotesSaga()]);
}