import styles from "./PageLinks.module.css";
type PageLinksProps = {
  testPages: {
    [k: string]: {
      order: string[];
      url: string;
    };
  };
  instance: string;
};

export const PageLinks: React.FC<PageLinksProps> = ({
  testPages,
  instance,
}) => {
  let order = testPages[instance.toLowerCase()]?.order;

  return (
    <ul className={styles.linksBlock}>
      {order.map((item) => {
        return (
          <li className={styles.li}>
            <a className={styles.link} target="_blank" rel="noreferrer" href={testPages[item].url} key={item}>
              {item}
            </a>
          </li>
        );
      })}
    </ul>
  );
};
