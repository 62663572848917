import { createSlice } from "@reduxjs/toolkit";
import {
  FetchStatus,
  TGetListOfSetvicesPayload,
  TGetRequestParamsPayload,
  TGetRequestsPayload,
  TRequestParams,
  TServiceRequestsList,
  TServicesList,
  TGetResultOfRequestPayload,
  TResultOfRequest,
  TGetLogsPayload,
} from "./types";

const testSlice = createSlice({
  name: "test",
  initialState: {
    serviceList: {
      status: 200,
      result: [],
    },
    fetchServiceListStart: null,
    fetchRequestsStatus: null,
    requests: {
      status: 200,
      result: {
        vars: {},
        params: [],
      },
    },
    requestsParams: null,
    fetchRequestsParamsStatus: null,
    resultOfRequest: {0:{ status: 200, result: []}},
    fetchResultOfRequest: {0:null},
    logs: null,
    fetchLogs: null
  } as {
    serviceList: TServicesList;
    fetchServiceListStart: FetchStatus | null;
    fetchRequestsStatus: FetchStatus | null;
    requests: {
      status: number;
      result: TServiceRequestsList;
    };
    requestsParams: TRequestParams | null;
    fetchRequestsParamsStatus: FetchStatus | null;
    resultOfRequest: TResultOfRequest;
    fetchResultOfRequest: {[key:string]: FetchStatus | null;};
    logs: string | null;
    fetchLogs: FetchStatus | null;
  },
  reducers: {
    fetchRequestsListStart: (
      state,
      action: { payload: TGetRequestsPayload }
    ) => {
      state.fetchServiceListStart = FetchStatus.PENDING;
    },
    fetchRequestsListSuccess: (
      state,
      action: {
        payload: {
          requests: { status: number; result: TServiceRequestsList };
        };
      }
    ) => {
      state.requests = action.payload.requests;
      state.fetchRequestsStatus = FetchStatus.SUCCESS;
    },
    fetchRequestsListFailure: (state, action) => {
      state.fetchRequestsStatus = FetchStatus.FAILURE;
      console.error("Loading Failure", action.payload);
    },
    clearRequestsListState: (state) => {
      state.fetchRequestsStatus = null;
      state.requests = {
        status: 200,
        result: {
          vars: {},
          params: [],
        },
      };
    },

    fetchRequestParamsListStart: (
      state,
      action: { payload: TGetRequestParamsPayload }
    ) => {
      state.fetchServiceListStart = FetchStatus.PENDING;
    },
    fetchRequestParamsListSuccess: (
      state,
      action: { payload: { params: TRequestParams } }
    ) => {
      state.requestsParams = action.payload.params;
      state.fetchRequestsStatus = FetchStatus.SUCCESS;
    },
    fetchRequestParamsListFailure: (state, action) => {
      state.fetchRequestsStatus = FetchStatus.FAILURE;
      console.error("Loading Failure", action.payload);
    },
    clearRequestParamsListState: (state) => {
      state.requestsParams = null;
      state.fetchRequestsStatus = null;
    },

    fetchServiceListStart: (
      state,
      action: { payload: TGetListOfSetvicesPayload }
    ) => {
      state.fetchServiceListStart = FetchStatus.PENDING;
    },
    fetchServiceListSuccess: (
      state,
      action: { payload: { serviceList: TServicesList } }
    ) => {
      state.serviceList = action.payload.serviceList;
      state.fetchServiceListStart = FetchStatus.SUCCESS;
    },
    fetchServiceListFailure: (state, action) => {
      state.fetchServiceListStart = FetchStatus.FAILURE;
      console.error("Loading Failure", action.payload);
    },
    clearServiceListState: (state) => {
      state.serviceList = { status: 200, result: [] };
      state.fetchServiceListStart = null;
    },

    fetchResultOfRequestStart: (
      state,
      action: { payload: TGetResultOfRequestPayload }
    ) => {
      state.fetchResultOfRequest[action.payload.index] = FetchStatus.PENDING;
    },
    fetchResultOfRequestSuccess: (
      state,
      action: { payload: { result: TResultOfRequest } }
    ) => {
      state.resultOfRequest = Object.assign(state.resultOfRequest, action.payload.result)
      state.fetchResultOfRequest[Object.keys(action.payload.result)[0]] = FetchStatus.SUCCESS;
    },
    fetchResultOfRequestFailure: (state, action) => {
      console.error("Loading Failure", action.payload);
    },
    clearResultOfRequestState: (state) => {
      state.fetchResultOfRequest = {0:null};
      state.resultOfRequest = {0:{ status: 200, result: []}}
    },

    fetchLogsStart: (
      state,
      action: {payload: TGetLogsPayload}
    ) => {
      state.fetchLogs = FetchStatus.PENDING;
    },

    fetchLogsSuccess: (
      state,
      action: { payload: { result: {status: number, result: string} } }
    ) => {
      state.logs = action.payload.result.result
      state.fetchLogs = FetchStatus.SUCCESS;
    },
    fetchLogsFailure: (state, action) => {
      state.fetchLogs = FetchStatus.FAILURE; 
    },
    clearLogsState: (state) => {
      state.fetchLogs = null;
      state.logs = null
    },
  },
});

export const { actions } = testSlice;
export const testReducer = testSlice.reducer;