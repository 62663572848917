import { Link, useNavigate } from "react-router-dom";
import { logoutApi } from "../../../api/auth/logoutApi";
import { TMenuItems } from "../../../api/menu/types";
import { AppPages } from "../../../types";
import styles from "./Menu.module.css";

type MenuProps = {
  menuItems: TMenuItems | [];
  children?: React.ReactNode;
  className?: string;
  selectedMenu?: string
};

type ListProps = {
  menuItems: TMenuItems | [];
  selectedMenu?: string
};

export const List: React.FC<ListProps> = ({ menuItems, selectedMenu }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    let accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      logoutApi(JSON.parse(accessToken))
        .then((response) => {
            navigate(AppPages.LOGIN);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <>
      <ul className={styles.list}>
        {menuItems.length > 0
          ? menuItems.map((item) => (
              <li>
                <Link to={item.menuUrl} className={selectedMenu === item.menuName ? styles.linkActive : styles.link} >
                  {item.menuName}
                </Link>
              </li>
            ))
          : null}
        <li
          className={`${styles.link} ${styles.linkLogout}`}
          onClick={handleClick}
        >
          Log out{" "}
          <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22.7812 31.4062C22.7812 31.5969 22.7055 31.7797 22.5707 31.9145C22.4359 32.0493 22.2531 32.125 22.0625 32.125H16.3125C16.1219 32.125 15.9391 32.0493 15.8043 31.9145C15.6695 31.7797 15.5938 31.5969 15.5938 31.4062V15.5938C15.5938 15.4031 15.6695 15.2203 15.8043 15.0855C15.9391 14.9507 16.1219 14.875 16.3125 14.875H22.0625C22.2531 14.875 22.4359 14.9507 22.5707 15.0855C22.7055 15.2203 22.7812 15.4031 22.7812 15.5938C22.7812 15.7844 22.7055 15.9672 22.5707 16.102C22.4359 16.2368 22.2531 16.3125 22.0625 16.3125H17.0312V30.6875H22.0625C22.2531 30.6875 22.4359 30.7632 22.5707 30.898C22.7055 31.0328 22.7812 31.2156 22.7812 31.4062ZM32.6335 22.9915L29.0398 19.3977C28.9392 19.2971 28.8111 19.2286 28.6716 19.2008C28.5321 19.173 28.3875 19.1872 28.2561 19.2417C28.1247 19.2961 28.0124 19.3883 27.9335 19.5066C27.8545 19.6249 27.8124 19.764 27.8125 19.9062V22.7812H22.0625C21.8719 22.7812 21.6891 22.857 21.5543 22.9918C21.4195 23.1266 21.3438 23.3094 21.3438 23.5C21.3438 23.6906 21.4195 23.8734 21.5543 24.0082C21.6891 24.143 21.8719 24.2188 22.0625 24.2188H27.8125V27.0938C27.8124 27.236 27.8545 27.3751 27.9335 27.4934C28.0124 27.6117 28.1247 27.7039 28.2561 27.7583C28.3875 27.8128 28.5321 27.827 28.6716 27.7992C28.8111 27.7714 28.9392 27.7029 29.0398 27.6023L32.6335 24.0085C32.7003 23.9418 32.7534 23.8625 32.7895 23.7752C32.8257 23.688 32.8443 23.5945 32.8443 23.5C32.8443 23.4055 32.8257 23.312 32.7895 23.2248C32.7534 23.1375 32.7003 23.0582 32.6335 22.9915Z" fill="#181a21"/>
</svg>

        </li>
      </ul>
    </>
  );
};

export const Menu: React.FC<MenuProps> = ({ menuItems, selectedMenu }) => {
  return (
    <nav className={styles.nav}>
      <List selectedMenu={selectedMenu} menuItems={menuItems} />
    </nav>
  );
};
