import styles from "./Burger.module.css";
import { Menu } from "../menu/Menu";
import { useState } from "react";
import { TMenuItems } from "../../../api/menu/types";

type BurgerProps = {
  children?: React.ReactNode;
  onClick: () => void;
  menuItems: TMenuItems | [];
};

export const Burger: React.FC<BurgerProps> = ({ onClick, menuItems }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div
      className={isOpen ? `${styles.burgerIsOpen}` : `${styles.burger}`}
      onClick={() => {
        setIsOpen(!isOpen);
        onClick();
      }}
    >
      <span className={styles.line}></span>
      <div className={isOpen ? `${styles.menu}` : `${styles.menuNone}`}>
        <Menu menuItems={menuItems}></Menu>
      </div>
    </div>
  );
};
