import { TFXOpenLoginPayload, TGetBalancesOfLPPayload, TGetListOfLPPayload } from "./types";

export namespace LpApi {
  export async function getBalance(payload: TGetBalancesOfLPPayload) {
    let server = `/api/treasury/LPs/${payload.lpId}`;
    if(payload.subLpId) {
      server += `/${payload.subLpId}`
    }
    let headers = new Headers({
      Authorization: `Bearer ${payload.accessToken}`,
      "content-type": "application/json",
    });
    try {
      const response = await fetch(server, {
        method: "GET",
        headers: headers,
      });

      if (response.status !== 200) {
        const errorText = await response.text();
        throw new Error(errorText);
      }

      const data = await response.json();
      return data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
  export async function getLPs(payload: TGetListOfLPPayload) {
    const server = "/api/treasury/LPs";
    let headers = new Headers({
      Authorization: `Bearer ${payload.accessToken}`,
      "content-type": "application/json",
    });
    try {
      const response = await fetch(server, {
        method: "GET",
        headers: headers,
      });

      if (response.status !== 200) {
        const errorText = await response.text();
        throw new Error(errorText);
      }
      const data = await response.json();
      return data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
  export async function getPABalance(payload: TGetBalancesOfLPPayload) {
    let server = `/api/treasury/LPs/pa/${payload.lpId}`;
    let headers = new Headers({
      Authorization: `Bearer ${payload.accessToken}`,
      "content-type": "application/json",
    });
    try {
      const response = await fetch(server, {
        method: "GET",
        headers: headers,
      });

      if (response.status !== 200) {
        const errorText = await response.text();
        throw new Error(errorText);
      }

      const data = await response.json();
      return data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
  export async function loginPAFXOpen(payload: TFXOpenLoginPayload) {
    let server = `/api/treasury/LPs/pa/fxopen_id/login/${payload.twoFactorCode}`;
    let headers = new Headers({
      Authorization: `Bearer ${payload.accessToken}`,
      "content-type": "application/json",
    });
    try {
      const response = await fetch(server, {
        method: "GET",
        headers: headers,
      });

      if (response.status !== 200) {
        const errorText = await response.text();
        throw new Error(errorText);
      }
      const data = await response.json();
      return data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
}
